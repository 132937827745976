import React, { useEffect, useState, useMemo, useRef } from 'react';
import { IoArrowBackCircle, IoPersonCircleOutline, IoPeopleCircleOutline, IoLogoUsd } from 'react-icons/io5';
import { useSnackbar } from 'react-simple-snackbar';
import { Link } from 'react-router-dom';

import Confirmation from 'components/UI/confirmation/Confirmation';
import SaveBanner from 'components/UI/saveBanner/SaveBanner';
import Button from 'components/UI/button/Button';
import Box from 'components/UI/box/Box';

import EmployeeLogins from './employeeLogins/EmployeeLogins';
import Invoices from './invoices/Invoices';

import { useStore } from 'context/';
import utils from 'utils/';
import api from 'api/';

import styling from './Settings.module.scss';

const Settings = ({ history, location }) => {
    // States
    const [{ showCloseAccountConfirmation }, setState] = useState({
        showCloseAccountConfirmation: false
    });

    const [activeItem, setActiveItem] = useState('personalInformation');


    // Store
    const [store] = useStore();


    // Hooks
    const [openSnackbar] = useSnackbar();


    // Refs
    const personalInformationRef = useRef({});
    const employeeLoginsRef = useRef({});
    const billingRef = useRef({});


    /**
     * Scrolls to the section with the given name.
     * @param menuItem {string} name of the section
     */
    const scrollTo = (menuItem) => {
        let top = 0;

        if (menuItem === 'employeeLogins') {
            top = employeeLoginsRef.current.offsetTop;
        }

        if (menuItem === 'billing') {
            top = billingRef.current.offsetTop;
        }

        window.scrollTo({ top, behavior: 'smooth' });
    };


    /**
     * Toggles the visibility of the close account confirmation.
     */
    const toggleShowCloseAccountConfirmation = () => {
        setState(prevState => ({ ...prevState, showCloseAccountConfirmation: !prevState.showCloseAccountConfirmation }));
    };


    /**
     * Deletes the account with all its data.
     * @returns {Promise<void>}
     */
    const closeAccount = async () => {
        try {
            await api.closeAccount();

            localStorage.clear();

            history.push('/auth/sign-in');

            openSnackbar('Account with all its data has been deleted successfully.');

        } catch (error) {
            console.error(error.message);
            openSnackbar(error.message);
        }
    };


    /**
     * Saves the changed information and updates the store.
     * @returns {Promise<void>}
     */
    const saveHandler = async () => {
        try {
        } catch (error) {
            console.error(error);
            openSnackbar(error.message);
        }
    };


    /**
     * Scrolls to the respective section if
     * a hash is present in the UrL.
     */
    useEffect(() => {
        if (location.hash === '#employeeLogins') {
            scrollTo('employeeLogins');
        }

        if (location.hash === '#billing') {
            scrollTo('billing');
        }
    }, [location.hash]);


    /**
     * Highlights the given menu item depending on the current scroll position.
     */
    useEffect(() => {
        const scrollHandler = () => {
            const refs = [
                personalInformationRef.current,
                employeeLoginsRef.current,
                billingRef.current
            ];

            if (refs.some(ref => !ref)) {
                return;
            }

            const personalInformationVisible = (refs[0].getBoundingClientRect().top - window.innerHeight) <= 0;
            const employeeLoginsVisible = (refs[1].getBoundingClientRect().top - window.innerHeight) <= 0;
            const billingVisible = (refs[2].getBoundingClientRect().top - window.innerHeight) <= 0;

            if (personalInformationVisible) {
                setActiveItem('personalInformation');
            }

            if (employeeLoginsVisible) {
                setActiveItem('employeeLogins');
            }

            if (billingVisible) {
                setActiveItem('billing');
            }
        };

        document.addEventListener('scroll', scrollHandler);

        return () => {
            document.removeEventListener('scroll', scrollHandler);
        };
    }, []);


    // Determine if there are unsaved changes
    const hasChanged = useMemo(() => {
        const oldData = {};

        const newData = {};

        return (JSON.stringify(oldData) !== JSON.stringify(newData));

    }, []);


    // Billing profile
    const billingProfile = store?.user?.organization?.billing || {};


    // Determines if boxes are shown
    const showTrialWarning = store?.user?.organization?.billing?.plan === 'trial';
    const paidThroughDate = store?.user?.organization?.billing?.paidThroughDate || new Date();


    return (
        <>
            <div className={styling.settings}>
                <aside className={styling.menu}>
                    <nav>
                        <ul>
                            <li className={styling.item}>
                                <Link to="/orders">
                                    <div className={styling.icon}>
                                        <IoArrowBackCircle/>
                                    </div>
                                    Back to Live Orders
                                </Link>
                            </li>

                            <li
                                className={activeItem === 'personalInformation' ? styling.active : styling.item}
                                onClick={() => scrollTo('personalInformation')}
                            >
                                <div className={styling.icon}>
                                    <IoPersonCircleOutline/>
                                </div>
                                Personal Information
                            </li>

                            <li
                                className={activeItem === 'employeeLogins' ? styling.active : styling.item}
                                onClick={() => scrollTo('employeeLogins')}
                            >
                                <div className={styling.icon}>
                                    <IoPeopleCircleOutline/>
                                </div>
                                Employee Logins
                            </li>

                            <li
                                className={activeItem === 'billing' ? styling.active : styling.item}
                                onClick={() => scrollTo('billing')}
                            >
                                <div className={styling.icon}>
                                    <IoLogoUsd/>
                                </div>
                                Billing
                            </li>
                        </ul>
                    </nav>
                </aside>

                <div className={styling.content}>
                    <h1>Settings</h1>

                    <section ref={personalInformationRef}>
                        <h2>Personal Information</h2>

                        <div>
                            <h6>Change Password</h6>

                            <p>
                                If you would like to change your password, please click the button below and you will receive an email with
                                a reset link and further instructions.
                            </p>

                            <Button>Change Password</Button>
                        </div>

                        <div>
                            <h6>Close Account</h6>

                            <p>
                                Closes your account and deletes all its data. This is permanently and cannot be undone.
                            </p>

                            <Button onClick={toggleShowCloseAccountConfirmation}>Close Account</Button>
                        </div>
                    </section>

                    <section ref={employeeLoginsRef}>
                        <h2>Employee Logins</h2>

                        <EmployeeLogins/>
                    </section>

                    <section ref={billingRef}>
                        <h2>Billing</h2>

                        <Box type="warning" hidden={!showTrialWarning}>
                            Your organization is using a free trial of iDo. You can test all features
                            until {utils.formatDate(new Date(paidThroughDate))}. Our sales team will contact you shortly to find the plan
                            that fits your needs best.
                        </Box>

                        <Box type="alert" hidden={new Date() < new Date(paidThroughDate)}>
                            Your subscription has expired. You can no longer use employee logins and receive orders. Please contact support
                            to create a subscription that fits your needs.
                        </Box>

                        <div>
                            <h6>Current Subscription</h6>

                            <p className={styling.currentPlan}>
                                You are subscribed to the
                                plan <b>"{billingProfile.plan}"</b> until {utils.formatDate(new Date(billingProfile.paidThroughDate))}.
                            </p>
                        </div>

                        <div>
                            <h6>Billing Profile</h6>

                            <div className={styling.box} hidden={billingProfile.vat}>
                                <Box type="warning">
                                    Your contact details are missing. Our support will contact you shortly.
                                </Box>
                            </div>

                            <p hidden={!billingProfile.vat}>
                                <b>{billingProfile.companyName}</b><br/>
                                {billingProfile.address}<br/>
                                {billingProfile.zipCode} {billingProfile.city}<br/>
                                {billingProfile.country}<br/>
                                {billingProfile.vat}
                            </p>
                        </div>

                        <div>
                            <h6>Invoices</h6>

                            <Invoices/>
                        </div>
                    </section>
                </div>
            </div>

            <SaveBanner hasChanged={hasChanged} saveHandler={saveHandler}/>

            <Confirmation
                open={showCloseAccountConfirmation}
                title="Close Account"
                message="Are you sure you want to close your account and permanently delete all your data."
                cancelHandler={toggleShowCloseAccountConfirmation}
                confirmHandler={closeAccount}
            />
        </>
    );
};

export default Settings;