import React, { useState, useEffect } from 'react';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { useSnackbar } from 'react-simple-snackbar';
import { Link } from 'react-router-dom';

import Checkbox from 'components/UI/checkbox/Checkbox';
import Spinner from 'components/UI/spinner/Spinner';
import Input from 'components/UI/input/Input';

import background from 'assets/photos/resort.jpg';

import { useStore } from 'context/';
import config from 'config/';
import utils from 'utils/';
import api from 'api/';

import styling from './Auth.module.scss';

const Auth = ({ history, location, match }) => {
    // State
    const [state, setState] = useState({
        organizationName: '',
        name: '',
        email: '',
        password: '',
        consentGiven: false,
        passwordForgottenEmail: '',
        newPassword: '',
        expiredMessageShown: false,
        isLoading: false
    });


    const {
        organizationName,
        name,
        email,
        password,
        consentGiven,
        passwordForgottenEmail,
        newPassword,
        expiredMessageShown,
        isLoading
    } = state;


    // Store
    const { 1: dispatch } = useStore();


    // Hooks
    const [openSnackbar] = useSnackbar();


    /**
     * Handles input field changes.
     * @param name {string} name of the input field
     * @param value {string} value that was entered into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        if (name === 'email') {
            value = value.trim();
        }

        setState(prevState => ({ ...prevState, [name]: value }));
    };


    /**
     * Toggles the consent given checkbox.
     */
    const checkboxHandler = () => {
        setState(prevState => ({ ...prevState, consentGiven: !prevState.consentGiven }));
    };


    /**
     * Formats the response and stores the relevant data in the global
     * state. It also stores the access pass and expiration time
     * in the localStorage, executes the expiration handler,
     * updates the document title and redirects the user.
     * @param res {object} authentication response
     */
    const handleAuthenticationResponse = (res) => {
        const update = {
            user: {
                ...res.user,
                organization: {
                    ...res.organization
                }
            }
        };

        dispatch({ type: 'update', payload: update });

        localStorage.setItem('access-pass', res.accessPass);
        localStorage.setItem('expiration-time', res.expiresAt);

        utils.expirationHandler(history, res.expiresAt);

        document.title = 'iDo Dashboard | ' + res.user.email;

        history.push((match.params.mode === 'sign-up') ? '/menus' : '/');
    };


    /**
     * Signs up a new user with the corresponding organization.
     * @param e {object} event object
     * @returns {Promise<void>}
     */
    const signUp = async (e) => {
        try {
            e.preventDefault();

            if (!config.regex.password.test(password)) {
                openSnackbar('The provided password is not strong enough.');
                return;
            }

            if (!consentGiven) {
                openSnackbar('Please accept the Terms & Conditions and Privacy Policy.');
                return;
            }

            setState(prevState => ({ ...prevState, isLoading: true }));

            const payload = {
                organizationName,
                name,
                email,
                password
            };

            const res = await api.signUp(payload);

            handleAuthenticationResponse(res);

        } catch (error) {
            console.error(error.message);
            openSnackbar('Failed to sign up. Please try another email address.');
            setState(prevState => ({ ...prevState, isLoading: false }));
        }
    };


    /**
     * Signs in a user with the given email and password.
     * @param e {object} event object
     * @returns {Promise<void>}
     */
    const signIn = async (e) => {
        try {
            e.preventDefault();

            setState(prevState => ({ ...prevState, isLoading: true }));

            const payload = {
                email,
                password
            };

            const res = await api.signIn(payload);

            handleAuthenticationResponse(res);

        } catch (error) {
            console.error(error.message);
            openSnackbar(utils.capitalize(error.message));
            setState(prevState => ({ ...prevState, isLoading: false }));
        }
    };


    /**
     * Requests a password reset link for the provided email address.
     * @param e {object} event object
     * @returns {Promise<void>}
     */
    const resetPassword = async (e) => {
        try {
            e.preventDefault();

            setState(prevState => ({ ...prevState, isLoading: true }));

            await api.resetPassword(passwordForgottenEmail);

            setState(prevState => ({ ...prevState, passwordForgottenEmail: '', isLoading: false }));

            openSnackbar('Instructions to reset your password were sent to your inbox.');

            history.push('/auth/sign-in');

        } catch (error) {
            console.error(error.message);
            openSnackbar(utils.capitalize(error.message));
        }
    };


    /**
     * Sets the new password if the provided
     * password reset token is valid.
     * @param e {object} event object
     * @returns {Promise<void>}
     */
    const updatePassword = async (e) => {
        try {
            e.preventDefault();

            const urlParams = new URLSearchParams(location.search);

            const email = urlParams.get('email');
            const token = urlParams.get('token');
            const id = urlParams.get('id');

            if (!email || !token || !id) {
                openSnackbar('This reset link is invalid.');
                return;
            }

            if (!newPassword || !config.regex.password.test(newPassword)) {
                openSnackbar('The provided password is not strong enough.');
                return;
            }

            setState(prevState => ({ ...prevState, isLoading: true }));

            const res = await api.updatePassword(id, email, token, newPassword);

            handleAuthenticationResponse(res);

        } catch (error) {
            console.error(error.message);
            openSnackbar(utils.capitalize(error.message));
        }
    };


    /**
     * Shows a message if the user was redirected to the sign
     * in because his session has been expired.
     */
    useEffect(() => {
        if (location.expired && !expiredMessageShown) {
            openSnackbar('Your session has been expired. Please sign in to continue.');
            setState(prevState => ({ ...prevState, expiredMessageShown: true }));
        }

    }, [expiredMessageShown, location.expired, openSnackbar]);


    // Get access pass and expiration time from localStorage
    const accessPass = localStorage.getItem('access-pass');
    const expirationTime = localStorage.getItem('expiration-time');


    // Redirect if user is already signed in or
    // clear localStorage if user is not signed
    // in or session has expired.
    if (accessPass && expirationTime && new Date(parseInt(expirationTime)) > new Date()) {
        history.push('/');
    } else {
        localStorage.clear();
    }


    const signUpForm = (
        <form className={styling.box} onSubmit={signUp}>
            <h1>Sign Up</h1>

            <Input
                value={organizationName}
                onChange={changeHandler}
                placeholder="Organization"
                name="organizationName"
                label="Organization"
                test={config.regex.name}
                required
                larger
            />

            <Input
                value={name}
                onChange={changeHandler}
                placeholder="Name and surname"
                name="name"
                label="Your Full Name"
                test={config.regex.name}
                required
                larger
            />

            <Input
                value={email}
                onChange={changeHandler}
                placeholder="Email"
                name="email"
                label="Email"
                type="email"
                autoComplete="email"
                test={config.regex.email}
                required
                larger
            />

            <Input
                value={password}
                onChange={changeHandler}
                placeholder="Password"
                name="password"
                label="Password"
                type="password"
                autoComplete="new-password"
                test={config.regex.password}
                caption="At least 8 characters, numbers and special characters"
                required
                larger
            />

            <div className={styling.consent}>
                <Checkbox
                    checked={consentGiven}
                    id="consent"
                    changeHandler={checkboxHandler}
                />

                <p>I confirm that I've read and accepted the Terms and Conditions and the Privacy Policy.</p>
            </div>

            <div className={styling.buttonWrapper}>
                <button className={styling.button} type="submit" hidden={isLoading}>Sign Up</button>

                <Spinner size="small" hidden={!isLoading}/>
            </div>

            <p className={styling.notice}>
                If you already have an account, click <Link to="/auth/sign-in">here</Link> to sign in.
            </p>
        </form>
    );


    const signInForm = (
        <form className={styling.box} onSubmit={signIn}>
            <h1>Sign In</h1>

            <Input
                value={email}
                onChange={changeHandler}
                placeholder="Email"
                name="email"
                label="Email"
                type="email"
                autoComplete="email"
                required
                larger
            />

            <Input
                value={password}
                onChange={changeHandler}
                placeholder="Password"
                name="password"
                label="Password"
                type="password"
                autoComplete="new-password"
                required
                larger
            />

            <Link to="/auth/forgot-password">Forgot Password?</Link>

            <div className={styling.buttonWrapper}>
                <button className={styling.button} type="submit" hidden={isLoading}>Sign In</button>

                <Spinner size="small" hidden={!isLoading}/>
            </div>

            <p className={styling.notice}>
                If you don't have an account yet, click <Link to="/auth/sign-up">here</Link> to sign up.
            </p>
        </form>
    );


    const forgotPasswordForm = (
        <form className={styling.box} onSubmit={resetPassword}>
            <h1>Forgot Password</h1>

            <p>Enter your email address to reset your password.</p>

            <Input
                value={passwordForgottenEmail}
                onChange={changeHandler}
                placeholder="Email"
                name="passwordForgottenEmail"
                label="Email"
                type="email"
                required
                larger
            />

            <div className={styling.buttonWrapper}>
                <button className={styling.button} type="submit" hidden={isLoading}>Reset Password</button>

                <Spinner size="small" hidden={!isLoading}/>
            </div>

            <p className={styling.notice}>
                Or click <Link to="/auth/sign-in">here</Link> to sign in to your account.
            </p>
        </form>
    );


    const setNewPassword = (
        <form className={styling.box} onSubmit={updatePassword}>
            <h1>New Password</h1>

            <p>Enter your new password</p>

            <Input
                value={newPassword}
                onChange={changeHandler}
                placeholder="Password"
                name="newPassword"
                label="Password"
                type="password"
                test={config.regex.password}
                required
                larger
            />

            <div className={styling.buttonWrapper}>
                <button className={styling.button} type="submit" hidden={isLoading}>Submit</button>

                <Spinner size="small" hidden={!isLoading}/>
            </div>
        </form>
    );


    const forms = {
        'sign-up': signUpForm,
        'sign-in': signInForm,
        'forgot-password': forgotPasswordForm,
        'new-password': setNewPassword
    };


    return (
        <main className={styling.container}>
            <div className={styling.wrapper}>
                <div className={styling.authentication}>
                    {forms[match.params.mode]}
                </div>
            </div>

            <div className={styling.wrapper}>
                <img src={background} className={styling.background} alt="background"/>

                <div className={styling.overlay}/>

                <div className={styling.information}>
                    <div className={styling.content}>
                        <div className={styling.subtitle}>
                            Boost your guest experience by offering an intuitive and quick booking and order system
                        </div>

                        <div className={styling.item}>
                            <div>
                                <IoCheckmarkCircleSharp/>
                            </div>

                            <div>
                                <h2>Available for all Industries</h2>

                                <p>iDo can be used by all kinds of businesses in the hospitality and gastronomy industry</p>
                            </div>
                        </div>

                        <div className={styling.item}>
                            <div>
                                <IoCheckmarkCircleSharp/>
                            </div>

                            <div>
                                <h2>White Labelling</h2>

                                <p>Customize iDo's booking and ordering system to match the appearance of your business</p>
                            </div>
                        </div>

                        <div className={styling.item}>
                            <div>
                                <IoCheckmarkCircleSharp/>
                            </div>

                            <div>
                                <h2>Improve Guest Experience</h2>

                                <p>Managing orders and bookings digitally with iDo improves the quality of your services</p>
                            </div>
                        </div>

                        <div className={styling.logos}/>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Auth;