import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import SnackbarProvider from 'react-simple-snackbar';
import smoothscroll from 'smoothscroll-polyfill';
import en from 'javascript-time-ago/locale/en';
import TimeAgo from 'javascript-time-ago';

import App from './App';
import { StoreProvider } from './context/';

import 'react-color-palette/lib/css/styles.css';

import './index.css';

// Initialize polyfills
smoothscroll.polyfill();

// Initialize locale
TimeAgo.addDefaultLocale(en);

const app = (
    <React.StrictMode>
        <SnackbarProvider>
            <StoreProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </StoreProvider>
        </SnackbarProvider>
    </React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));