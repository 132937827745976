import React from 'react';
import { IoInformation, IoAlert } from 'react-icons/io5';

import styling from './Box.module.scss';

const Box = ({ children, type = 'info', addTopMargin, addBottomMargin, hidden }) => {
    let style = styling[type];

    if (addTopMargin) {
        style += ' ' + styling.addTopMargin;
    }

    if (addBottomMargin) {
        style += ' ' + styling.addBottomMargin;
    }

    const icons = {
        info: <IoInformation/>,
        warning: <IoInformation/>,
        alert: <IoAlert/>
    };

    return (
        <div className={style} hidden={hidden}>
            <div className={styling.icon}>
                {icons[type]}
            </div>

            <p>{children}</p>
        </div>
    );
};

export default Box;