import React, { useEffect, useRef } from 'react';
import SimpleBar from 'simplebar';

import styling from './Table.module.scss';

const Table = ({ headers = [], rows = [], isScrollable = false }) => {
    // Ref
    const ref = useRef({});


    const getStyling = (styles = []) => {
        return styles.reduce((a, c) => a + ' ' + styling[c], '');
    };


    /**
     * Adds a custom scroll bar to the table.
     */
    useEffect(() => {
        let instance;

        if (isScrollable) {
            instance = new SimpleBar(ref.current, {
                forceVisible: true,
                autoHide: false
            });
        }

        return () => {
            instance && instance.unMount();
        };
    }, [isScrollable]);


    return (
        <div className={styling.container}>
            <div className={styling.wrapper}>
                <table className={styling.table} ref={ref}>
                    <thead>
                    <tr>
                        {headers.map((header, i) => (
                            <th className={getStyling(header.styles)} style={{ minWidth: header.minWidth || '' }} key={i}>
                                {header.value}
                            </th>
                        ))}
                    </tr>
                    </thead>

                    <tbody>
                    {rows.map((row, i) => (
                        <tr key={i}>
                            {row.map((c, i) => (<td className={getStyling(c.styles)} key={i}>{c.value}</td>))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Table;