import fastCopy from 'fast-copy';
import { saveAs } from 'file-saver';

/**
 * Returns a promise that resolves after a given time.
 * @param time {number} time in milliseconds after which the promise should resolve
 * @returns {Promise<unknown>}
 */
const sleep = async (time = 50) => new Promise(resolve => setTimeout(resolve, time));

/**
 * Deep copies a given object.
 * @param obj {object} object that should be copied
 * @returns {*} a deep copy of the object that was provided
 */
const copy = (obj) => fastCopy(obj);

/**
 * Capitalizes the first character of a given string.
 * @param string {string} the string, whose first letter should be capitalized
 * @returns {string}
 */
const capitalize = (string = '') => {
    return string[0].toUpperCase() + string.slice(1);
};

/**
 * Calculates the expiration time and redirects the user
 * to the sign in when their session has expired.
 * @param history {object} history object
 * @param expiresIn {string} timestamp when the session expires
 */
const expirationHandler = (history, expiresIn) => {
    const expirationTime = expiresIn - Date.now();


    setTimeout(() => {
        localStorage.clear();

        history.push({
            pathname: '/auth/sign-in',
            expired: true
        });
    }, expirationTime);
};

/**
 * Returns the value for the given CSS variable name.
 * @param variableName {string} name of the CSS variable
 * @returns {string} the value for the given CSS variable
 */
const getCSSVariable = (variableName) => {
    return getComputedStyle(document.body).getPropertyValue(variableName);
};

/**
 * Saves a files to the device of the user.
 * @param blob {object} the file that should be saved as blob
 * @param name {string} name of the file
 * @returns {*}
 */
const saveFileToDevice = (blob, name) => {
    return saveAs(blob, name);
};

/**
 * Formats a given date to the locale date string.
 * @param date {object} the date object to format
 * @returns {string} the formatted date string
 */
const formatDate = (date) => {
    if (!date) {
        return '';
    }

    return date.toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' });
};

/**
 * Replaces all whitespaces with a given replacement
 * character and removes all special characters.
 * @param value {string} the string for which all special characters should be removed
 * @param replaceChar {string} the replacement value for whitespaces in the given string
 * @returns {string} the provided value with replaced whitespaces and without special characters
 */
const removeSpecialChars = (value = '', replaceChar = '') => {
    return value
        .replace(/\s/g, replaceChar)
        .replace(/[^a-zA-Z-_]/g, '')
        .toLowerCase();
};

const service = {
    sleep,
    copy,
    capitalize,
    expirationHandler,
    getCSSVariable,
    saveFileToDevice,
    formatDate,
    removeSpecialChars
};

export default service;