import React from 'react';
import { IoAddCircle } from 'react-icons/io5';

import styling from './Elements.module.scss';

const AddCategoryButton = ({ addElement, path }) => (
    <button className={styling.addCategory} onClick={() => addElement(path)}>
        <div className={styling.addIcon}>
            <IoAddCircle/>
        </div>

        <div>Add Category</div>
    </button>
);

export default AddCategoryButton;