import React from 'react';

import Button from '../button/Button';

import styling from './Placeholder.module.scss';

const Placeholder = ({ title, callToAction, callToActionLabel, children, size = 'regular', hidden }) => (
    <div className={styling[size]} hidden={hidden}>
        <div className={styling.title}>{title}</div>

        <p className={styling.subtitle}>{children}</p>

        <Button onClick={callToAction} hidden={!callToAction}>
            {callToActionLabel || 'Get started now'}
        </Button>
    </div>
);

export default Placeholder;