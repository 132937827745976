import React, { useState, useEffect, useCallback } from 'react';
import { IoOpenOutline, IoCheckmarkCircle } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import Spinner from 'components/UI/spinner/Spinner';
import Box from 'components/UI/box/Box';

import { useStore } from 'context/';
import utils from 'utils/';
import api from 'api/';

import styling from './Dashboard.module.scss';

const Dashboard = () => {
    // State
    const [{ locationCount, orderCount, menuCount, isLoading }, setState] = useState({
        locationCount: 0,
        orderCount: 0,
        menuCount: 0,
        isLoading: true
    });


    // Store
    const [store] = useStore();


    /**
     * Fetches the dashboard data and stores it in the state.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchData = useCallback(async () => {
        try {
            const res = await api.getDashboardData();

            await utils.sleep(250);

            setState(prevState => ({ ...prevState, ...res, isLoading: false }));

        } catch (error) {
            console.error(error.message);
        }
    }, []);


    /**
     * Fetches the dashboard data when fetchData changes.
     */
    useEffect(fetchData, [fetchData]);


    // Determine if boxes are shown
    const showTrialWarning = store?.user?.organization?.billing?.plan === 'trial';
    const paidThroughDate = store?.user?.organization?.billing?.paidThroughDate || new Date();


    return (
        <>
            <div className={styling.banner}/>

            <div className={styling.content}>
                <div className={styling.header}>
                    <div>
                        <h1>Need a hand?</h1>
                        <p>Contact our support to get further assistance<br/>setting up iDo.</p>
                        <a href="mailto:info@mosaic.mc?subject=Question regarding iDo" target="_blank" rel="noreferrer noopener">
                            <IoOpenOutline/>
                            <span>Contact Support</span>
                        </a>
                    </div>
                </div>

                <Box type="warning" hidden={!showTrialWarning} addBottomMargin>
                    Your organization is using a free trial of iDo. You can test all features
                    until {utils.formatDate(new Date(paidThroughDate))}. Our sales team will contact you shortly to find the plan that fits
                    your needs best.
                </Box>

                <Box type="alert" hidden={new Date() < new Date(paidThroughDate)} addBottomMargin>
                    Your subscription has expired. You can no longer use employee logins and receive orders. Please contact support to
                    create a subscription that fits your needs.
                </Box>

                <h2>Your Progress</h2>

                <div className={styling.progress} hidden={isLoading}>
                    <div className={styling.step}>
                        <div>Create a Menu</div>
                        <p>First, create one or more menus and every item your guests can order.</p>
                        <Link to="/menus/new">Create Menu</Link>

                        <div className={styling.tick} hidden={!menuCount}>
                            <IoCheckmarkCircle/>
                        </div>
                    </div>

                    <div className={styling.step}>
                        <div>Create a Location</div>
                        <p>Next, create one or more locations and assign your menus to them.</p>
                        <Link to="/locations">Create Location</Link>

                        <div className={styling.tick} hidden={!locationCount}>
                            <IoCheckmarkCircle/>
                        </div>

                        <div className={styling.overlay} hidden={menuCount}/>
                    </div>

                    <div className={styling.step}>
                        <div>Receive Orders</div>
                        <p>You are all set up and ready to receive your first order at your locations.</p>
                        <Link to="/orders">Live Orders</Link>

                        <div className={styling.tick} hidden={!orderCount}>
                            <IoCheckmarkCircle/>
                        </div>

                        <div className={styling.overlay} hidden={locationCount}/>
                    </div>
                </div>

                <Spinner hidden={!isLoading}/>
            </div>
        </>
    );
};

export default Dashboard;