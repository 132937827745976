import React, { useEffect, useCallback, useRef } from 'react';
import QRCodeStyling from 'qr-code-styling';

import utils from 'utils';

import styling from './QRCode.module.scss';

const QRCode = ({ value = '', downloadable, larger, hidden }) => {
    // Refs
    const containerRef = useRef({});
    const qrCodeRef = useRef({});


    /**
     * Returns QR code options.
     */
    const getQRCodeOptions = useCallback(() => ({
        width: larger ? 170 : 150,
        height: larger ? 170 : 150,
        data: value,
        margin: 0,
        qrOptions: {
            typeNumber: '0',
            mode: 'Byte',
            errorCorrectionLevel: 'Q'
        },
        dotsOptions: {
            type: 'rounded',
            color: utils.getCSSVariable('--color-1')
        },
        backgroundOptions: {
            color: utils.getCSSVariable('--color-2')
        },
        cornersSquareOptions: {
            type: 'extra-rounded',
            color: utils.getCSSVariable('--color-1')
        }
    }), [larger, value]);


    /**
     * Download the current QR code.
     * @returns {Promise<void>}
     */
    const download = async () => {
        const opts = getQRCodeOptions();

        opts.width = 1000;
        opts.height = 1000;

        const qrCode = new QRCodeStyling(opts);

        await qrCode.download();
    };


    /**
     * Generates and mounts the QR code
     * when getQRCodeOption updates.
     */
    useEffect(() => {
        qrCodeRef.current = new QRCodeStyling(getQRCodeOptions());

        qrCodeRef.current.append(containerRef.current);

    }, [getQRCodeOptions]);


    return (
        <div className={styling.container}>
            <div className={larger ? styling.larger : styling.qrCode} hidden={hidden}>
                <div ref={containerRef}/>
            </div>

            <button
                className={styling.download}
                onClick={download}
                hidden={!downloadable}
            >
                Download
            </button>
        </div>
    );
};

export default QRCode;