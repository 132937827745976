import React, { useEffect, useCallback, useRef } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import SaveBanner from 'components/UI/saveBanner/SaveBanner';
import Spinner from 'components/UI/spinner/Spinner';
import Settings from './settings/Settings';
import Box from 'components/UI/box/Box';
import Builder from './builder/Builder';

import { withMenuContext, useMenuContext } from './context/';
import utils from 'utils';
import api from 'api';

import styling from './Menu.module.scss';


const Menu = ({ history, match }) => {
    // Hooks
    const [state, dispatch] = useMenuContext();
    const [openSnackbar] = useSnackbar();


    const {
        id,
        name,
        currency,
        logo,
        coverImage,
        color,
        items,
        isSaving,
        isLoading
    } = state;


    // Refs
    const initialData = useRef({});
    const isFetching = useRef(false);


    /**
     * Creates a new menu or updates an
     * existing one if menu ID is set.
     * @returns {Promise<void>}
     */
    const saveMenu = async () => {
        try {
            dispatch({ type: 'update', payload: { isSaving: true } });

            const payload = { name, currency, color, logo, coverImage, items };

            if (!match.params.id) {
                const res = await api.createMenu(payload);
                history.push('/menus/edit/' + res._id);

            } else {
                await api.updateMenu(match.params.id, payload);
                initialData.current = { name, currency, color, logo, coverImage, items, isSet: true };
            }

            dispatch({ type: 'update', payload: { isSaving: false } });

            await utils.sleep(450);

            openSnackbar('Menu has been saved successfully');

        } catch (error) {
            console.error(error.message);
            openSnackbar('Failed to save menu');
        }
    };


    /**
     * Fetches the menu data.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchMenu = useCallback(async () => {
        try {
            if (isFetching.current) {
                return;
            }

            isFetching.current = true;

            dispatch({ type: 'update', payload: { isLoading: true } });

            const res = await api.getMenuById(match.params.id);

            initialData.current = {
                name: res.name,
                currency: res.currency,
                color: res.color,
                logo: res.logo,
                coverImage: res.coverImage,
                items: res.items,
                isSet: true
            };

            const stateUpdate = {
                id: res._id,
                name: res.name,
                currency: res.currency,
                color: res.color,
                logo: res.logo,
                coverImage: res.coverImage,
                items: res.items || [],
                isLoading: false
            };

            dispatch({ type: 'update', payload: stateUpdate });

            isFetching.current = false;

        } catch (error) {
            console.error(error.message);
            history.push('/menus');

            setTimeout(() => {
                openSnackbar('The selected menu does not exist');
            }, 500);
        }
    }, [match.params.id, history, openSnackbar, dispatch]);


    /**
     * Sets the initial data as reference to
     * determine if there are changes.
     */
    useEffect(() => {
        if (!initialData.current.isSet) {
            initialData.current = { name, color, logo, coverImage, items, isSet: true };
        }
    }, [name, color, logo, coverImage, items]);


    /**
     * Fetches the menu data whenever the menu ID changes.
     */
    useEffect(() => {
        if (!id && match.params.id) {
            fetchMenu();
        }
    }, [id, match.params.id, fetchMenu]);


    // Determine if data has changed
    const originalData = JSON.stringify({
        name: initialData.current.name,
        currency: initialData.current.currency,
        color: initialData.current.color,
        logo: initialData.current.logo,
        coverImage: initialData.current.coverImage,
        items: initialData.current.items
    });

    const currentData = JSON.stringify({ name, currency, color, logo, coverImage, items });

    const hasChanged = name.length && (originalData !== currentData);


    // Menu details
    const content = (
        <div className={styling.container}>
            <Link to="/menus" className={styling.back}>
                <IoArrowBackCircle/>
                <span>Back to all Menus</span>
            </Link>

            <h1>{id ? name : 'Create new Menu'}</h1>

            <Settings/>

            <Box type="info" addTopMargin addBottomMargin>
                Build your menu with our editor below. You can create an item that represents for example a dish or service. Items need to
                be part of a category, which makes it easier for your guests to find what they looking for.
            </Box>

            <Builder/>

            <SaveBanner
                hasChanged={hasChanged}
                showSpinner={isSaving}
                saveHandler={saveMenu}
            />
        </div>
    );


    return isLoading ? <Spinner/> : content;
};

export default withMenuContext(Menu);