import React, { useState, useEffect, useRef } from 'react';
import { ColorPicker } from 'react-color-palette';

import Spinner from 'components/UI/spinner/Spinner';

import styling from './ResponseColorPicker.module.scss';

const ResponsiveColorPicker = ({ color, updateColor }) => {
    // State
    const [{ width, height }, setState] = useState({
        width: 0,
        height: 0
    });

    // Refs
    const container = useRef({});

    /**
     * Resizes the color picker when the window is resized.
     */
    useEffect(() => {
        let intervalId;
        let isMounted = true;

        const resizeHandler = () => {
            const width = container.current.getBoundingClientRect?.()?.width;
            const height = container.current.getBoundingClientRect?.()?.height;

            if (isMounted && width && height) {
                clearInterval(intervalId);
                setState({ width, height: height - 137 });
            }
        };

        intervalId = setInterval(resizeHandler, 1500);

        setTimeout(resizeHandler, 150);

        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
            clearInterval(intervalId);
            isMounted = false;
        };
    }, []);

    if (!color) {
        return null;
    }

    return (
        <div className={styling.container} ref={container}>
            <ColorPicker
                width={width}
                height={height}
                color={color}
                onChange={updateColor}
                hideHSV
                hideRGB
                dark
            />

            <div className={styling.spinner} hidden={width && height}>
                <Spinner size="small"/>
            </div>
        </div>
    );
};

export default ResponsiveColorPicker;