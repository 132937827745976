import React, { useState, useEffect } from 'react';
import { IoRefreshCircle } from 'react-icons/io5';
import { useSnackbar } from 'react-simple-snackbar';

import Button from 'components/UI/button/Button';
import Input from 'components/UI/input/Input';
import Modal from 'components/UI/modal/Modal';

import api from 'api/';

import styling from './ChangePassword.module.scss';

const ChangePassword = ({ open, email, id, closeHandler }) => {
    // State
    const [{ password }, setState] = useState({
        password: ''
    });


    // Hooks
    const [openSnackbar] = useSnackbar();


    /**
     * Handles input field changes.
     * @param value {string} the value that was entered into the input field
     */
    const changeHandler = ({ target: { value } }) => {
        setState({ password: value });
    };


    /**
     * Updates the password of the employee login
     * with the provided employee login ID.
     * @returns {Promise<void>}
     */
    const updatePassword = async () => {
        try {
            closeHandler();

            await api.changeEmployeeLoginPassword(id, password);

            openSnackbar('Successfully changed password of the employee login');

        } catch (error) {
            console.error(error.message);
            openSnackbar(error.message);
        }
    };


    /**
     * Resets the data when the component mounts.
     */
    useEffect(() => {
        if (open) {
            setState({ password: '' });
        }
    }, [open]);


    return (
        <Modal open={open}>
            <div className={styling.container}>
                <div className={styling.icon}>
                    <IoRefreshCircle/>
                </div>

                <h1>Change Password</h1>

                <p>
                    Enter the a new password for the employee login with the email address <b>{email}</b>. Please make sure to store this
                    password securely.
                </p>

                <Input
                    value={password}
                    label="New Password"
                    onChange={changeHandler}
                    placeholder="New Password"
                />

                <div className={styling.controls}>
                    <Button type="clear" onClick={closeHandler}>Cancel</Button>
                    <Button onClick={updatePassword}>Save</Button>
                </div>
            </div>
        </Modal>
    );
};

export default ChangePassword;