import React, { useContext, useReducer, createContext } from 'react';

// Initial state
const initialState = {
    stagedElementInstructions: null,
    id: '',
    name: '',
    currency: 'Euro (EUR)',
    logo: '',
    coverImage: '',
    color: {
        hex: '#7d3b48',
        hsv: { h: 348, s: 53, v: 49 },
        rgb: { r: 125, g: 59, b: 72 }
    },
    items: [{
        type: 'category',
        multiLanguageLabels: {
            en: {
                name: 'Example Category',
                description: ''
            }
        },
        id: Date.now(),
        items: [{
            type: 'item',
            multiLanguageLabels: {
                en: {
                    name: 'Example Item',
                    description: 'Click to change this item...'
                }
            },
            price: '20',
            id: Date.now() + 100,
            isVegetarian: false,
            isVegan: false
        }]
    }],
    showTour: false,
    isSaving: false,
    isLoading: false
};


// Create the menu context
const MenuContext = createContext();


/**
 * The reducer for the context.
 * @param state {object} current state
 * @param action {object} dispatched action
 * @returns {(*)|*}
 */
const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return { ...state, ...action.payload };
        default:
            console.error('action', action.type, 'is invalid');
            return state;
    }
};


/**
 * Wraps the given component with the menu context provider.
 * @param Component {function} the component that should be wrapped with the context provider
 * @returns {function(*)}
 */
const withMenuContext = (Component) => {
    const WithMenuContext = (props) => {
        const [state, dispatch] = useReducer(reducer, initialState);

        return (
            <MenuContext.Provider value={[state, dispatch]}>
                <Component {...props}/>
            </MenuContext.Provider>
        );
    };

    return (props) => <WithMenuContext {...props} />;
};


/**
 * Access the menu context and the dispatch function.
 * @returns {array} array with the current state and dispatch function
 */
const useMenuContext = () => {
    const context = useContext(MenuContext);

    if (!context) {
        throw new Error('useMenuContext is not used within a MenuContextProvider');
    }

    return context;
};


export { withMenuContext, useMenuContext };