import React from 'react';
import { IoMove, IoTrashOutline } from 'react-icons/io5';

import styling from './Elements.module.scss';

const Item = ({ dragStart, addTutNames, stageUpdateElement, deleteHandler, path, multiLanguageLabels, price, currencySymbol }) => (
    <div
        className={styling.item}
        onDragStart={dragStart}
        data-path={path}
        data-tut={addTutNames ? 'menu-step-10' : ''}
        draggable
    >
        <div onClick={() => stageUpdateElement(path)}>
            <div className={styling.name}>
                {multiLanguageLabels?.en?.name} <span>- {(price | 0).toFixed(2)} {currencySymbol}</span>
            </div>

            <div className={styling.description}>
                {multiLanguageLabels?.en?.description || ''}
            </div>
        </div>

        <div className={styling.actions}>
            <div className={styling.icon} data-tut={addTutNames ? 'menu-step-11' : ''}>
                <IoMove/>
            </div>

            <button className={styling.icon} onClick={() => deleteHandler(path)}>
                <IoTrashOutline/>
            </button>
        </div>
    </div>
);

export default Item;