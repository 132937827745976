import React from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { motion } from 'framer-motion';
import get from 'lodash/get';

import AddCategoryButton from './elements/AddCategoryButton';
import ElementDetails from './elementDetails/ElementDetails';
import Category from './elements/Category';
import Item from './elements/Item';

import { useMenuContext } from '../context/';
import config from 'config/';
import utils from 'utils/';

import styling from './Builder.module.scss';


const Builder = () => {
    // Hooks
    const [{ items, currency }, dispatch] = useMenuContext();
    const [openSnackbar] = useSnackbar();


    /**
     * Constructs the path in the array of
     * items for a given path string.
     * @param path {string} initial path that should be constructed
     * @returns {object} the path in the array of all items
     */
    const constructPath = (path) => {
        const depth = (path === '-1') ? '0' : path.split('.').length;

        const splitPath = path.split('.');
        const index = splitPath.pop();

        const location = splitPath.map(x => `[${x}]`).join('.items');

        return { index, location, depth };
    };


    /**
     * Determines the target location of the added
     * element and stores it in the state.
     * @param path {string} path of the new element
     */
    const stageNewElement = (path) => {
        const targetLocation = constructPath(path);

        targetLocation.isNew = true;
        targetLocation.elementData = {};
        targetLocation.canBeCategory = targetLocation.depth < 2;
        targetLocation.canBeItem = targetLocation.depth > 0;
        targetLocation.multiLanguageLabels = {};

        dispatch({ type: 'update', payload: { stagedElementInstructions: targetLocation } });
    };


    /**
     * Increases the height of the dropzone over
     * which the item is dragged.
     * @param e {object} event object
     */
    const dragOver = (e) => {
        e.persist();
        e.preventDefault();
        e.target.style.height = '4rem';
    };


    /**
     * Decreases the height of the dropzone over
     * which the item was dragged when it
     * leaves the dropzone.
     * @param e {object} event object
     */
    const dragLeave = (e) => {
        e.persist();
        e.preventDefault();
        e.target.style.height = '2rem';
    };


    /**
     * Moves the dropped item to the desired position.
     * @param e {object} event object
     */
    const drop = (e) => {
        e.persist();
        e.preventDefault();
        e.target.style.height = '2rem';

        const itemsCopy = utils.copy(items);

        const pathString = e.target.getAttribute('data-path');

        const sourceLocation = JSON.parse(e.dataTransfer.getData('text'));
        const targetLocation = constructPath(pathString);

        const sourcePath = sourceLocation.location;
        const targetPath = targetLocation.location;

        const sourceIndex = sourceLocation.index;

        const source = sourcePath ? get(itemsCopy, sourcePath + '.items') : itemsCopy;
        const target = targetPath ? get(itemsCopy, targetPath + '.items') : itemsCopy;

        const item = get(itemsCopy, sourcePath ? (sourcePath + `.items[${sourceIndex}]`) : sourceIndex);

        if (item.type === 'category' && targetLocation.depth > 2) {
            openSnackbar('You cannot move a sub-category into a sub-category');
            return;
        }

        if (item.type === 'item' && targetLocation.depth === 1) {
            openSnackbar('Every item has to be placed inside of a category');
            return;
        }

        source.splice(sourceLocation.index, 1);
        target.splice(targetLocation.index, 0, item);

        dispatch({ type: 'update', payload: { items: itemsCopy } });
    };


    /**
     * Sets the path of the dragged item as dataTransfer text.
     * @param e {object} event object
     */
    const dragStart = (e) => {
        const pathString = e.target.getAttribute('data-path');

        const path = constructPath(pathString);

        e.dataTransfer.setData('text', JSON.stringify(path));
    };


    /**
     * Determines the target location of the element
     * that should be updated.
     * @param path {string} the path of the element
     */
    const stageUpdateElement = (path) => {
        const targetLocation = constructPath(path);

        const { location, index } = targetLocation;

        const itemsCopy = utils.copy(items);

        const item = location ? get(itemsCopy, `${location}.items[${index}]`) : itemsCopy[index];

        targetLocation.isNew = false;
        targetLocation.elementData = item;

        dispatch({ type: 'update', payload: { stagedElementInstructions: targetLocation } });
    };


    /**
     * Deletes the current element.
     * @param path {string} the path of the element
     */
    const deleteHandler = (path) => {
        const { location, index } = constructPath(path);

        const itemsCopy = utils.copy(items);

        const target = location ? get(itemsCopy, location + '.items') : itemsCopy;

        target.splice(index, 1);

        dispatch({ type: 'update', payload: { items: itemsCopy } });
    };


    /**
     * Renders a dropzone with the given path.
     * @param path {string} the path of the dropzone
     * @returns {JSX.Element}
     * @constructor
     */
    const Dropzone = ({ path }) => (
        <div
            className={styling.dropzone}
            onDragOver={dragOver}
            onDragLeave={dragLeave}
            onDrop={drop}
            data-path={path}
        />
    );


    // Determine currency symbol
    const currencySymbol = config.currencies.find(c => c.value === currency)?.symbol || '';


    return (
        <>
            <motion.div transition={config.animation.layoutTransition} layout>
                {items.map((item, firstIndex) => (
                    <motion.div key={item.id} layout>
                        <Category
                            multiLanguageLabels={item.multiLanguageLabels || {}}
                            dragStart={dragStart}
                            stageUpdateElement={stageUpdateElement}
                            deleteHandler={deleteHandler}
                            addElement={stageNewElement}
                            addTutNames={firstIndex === 0}
                            path={`${firstIndex}`}
                        >
                            {item.items.map((item, secondIndex) => {
                                if (item.type === 'category') {
                                    return (
                                        <motion.div key={item.id} layout>
                                            <Category
                                                multiLanguageLabels={item.multiLanguageLabels || {}}
                                                dragStart={dragStart}
                                                stageUpdateElement={stageUpdateElement}
                                                deleteHandler={deleteHandler}
                                                addElement={stageNewElement}
                                                path={`${firstIndex}.${secondIndex}`}
                                            >
                                                {item.items.map((item, thirdIndex) => (
                                                    <motion.div key={item.id} layout>
                                                        <Item
                                                            {...item}
                                                            dragStart={dragStart}
                                                            stageUpdateElement={stageUpdateElement}
                                                            deleteHandler={deleteHandler}
                                                            path={`${firstIndex}.${secondIndex}.${thirdIndex}`}
                                                            currencySymbol={currencySymbol}
                                                        />

                                                        <Dropzone path={`${firstIndex}.${secondIndex}.${thirdIndex + 1}`}/>
                                                    </motion.div>
                                                ))}
                                            </Category>

                                            <Dropzone path={`${firstIndex}.${secondIndex + 1}`}/>
                                        </motion.div>
                                    );
                                }

                                return (
                                    <motion.div key={item.id}>
                                        <Item
                                            {...item}
                                            dragStart={dragStart}
                                            stageUpdateElement={stageUpdateElement}
                                            deleteHandler={deleteHandler}
                                            addTutNames={firstIndex === 0 && secondIndex === 0}
                                            path={`${firstIndex}.${secondIndex}`}
                                            currencySymbol={currencySymbol}
                                        />

                                        <Dropzone path={`${firstIndex}.${secondIndex + 1}`}/>
                                    </motion.div>
                                );
                            })}
                        </Category>

                        <Dropzone path={`${firstIndex + 1}`}/>
                    </motion.div>
                ))}
            </motion.div>

            <AddCategoryButton addElement={stageNewElement} path={'-1'}/>

            <ElementDetails/>
        </>
    );
};

export default Builder;