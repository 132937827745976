import React, { createContext, useContext, useReducer } from 'react';

const Context = createContext({});

const initialState = {
    user: null,
    settingsMenuOpen: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return { ...state, ...action.payload };
        case 'updateUser':
            return {
                ...state,
                user: {
                    ...state.user,
                    name: action.payload.name,
                    email: action.payload.email,
                    organization: {
                        ...state.user.organization,
                        name: action.payload.organizationName
                    }
                }
            };
        default:
            return state;
    }
};

export const StoreProvider = ({ children }) => {
    const [store, dispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={[store, dispatch]}>
            {children}
        </Context.Provider>
    );
};

export const useStore = () => useContext(Context);