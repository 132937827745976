import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactTimeAgo from 'react-time-ago';

import OrderDetails from 'components/orderDetails/OrderDetails';
import Placeholder from 'components/UI/placeholder/Placeholder';
import Dropdown from 'components/UI/dropdown/Dropdown';
import Spinner from 'components/UI/spinner/Spinner';
import Modal from 'components/UI/modal/Modal';

import Order from './order/Order';

import api from 'api/';

import styling from './Orders.module.scss';

const Orders = ({ locations, liveMode }) => {
    // State
    const [{ orders, orderDetailsVisible, orderDetails, locationId, status, page, isLoading }, setState] = useState({
        orders: [],
        orderDetails: null,
        orderDetailsVisible: false,
        locationId: '',
        status: 'all',
        page: 1,
        isLoading: true
    });


    // Refs
    const intervalId = useRef(null);


    /**
     * Fetches all orders for the current filters.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchOrders = useCallback(async () => {
        try {
            if (!locationId) {
                setState(prevState => ({ ...prevState, isLoading: false }));
                return;
            }

            setState(prevState => ({ ...prevState, isLoading: true }));

            const orders = await api.getOrdersByLocation(locationId, page, status);

            setState(prevState => ({ ...prevState, orders, isLoading: false }));

        } catch (error) {
            console.error(error.message);
        }
    }, [locationId, page, status]);


    /**
     * Handles dropdown field changes.
     * @param name {string} the name of the dropdown
     * @param value {string} the option that was selected
     */
    const dropdownChangeHandler = (name, value) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };


    /**
     * Shows the given order details.
     * @param orderDetailsVisible {boolean} determines if the order details should be visible
     * @param orderDetails {object} the order data that should be shown
     */
    const showOrderDetails = (orderDetailsVisible, orderDetails = false) => {
        setState(prevState => ({
            ...prevState,
            orderDetailsVisible,
            ...(orderDetails && { orderDetails })
        }));
    };


    /**
     * Fetches orders when the fetchOrders function changes.
     */
    useEffect(() => {
        setState(prevState => ({ ...prevState, locationId: (locations?.[0]?.value || '') }));

        if (liveMode) {
            clearInterval(intervalId.current);
            intervalId.current = setInterval(fetchOrders, 7000);
        }

        fetchOrders();

    }, [fetchOrders, liveMode, locations]);


    /**
     * Clears the interval when the component unmounts.
     */
    useEffect(() => {
        return () => {
            clearInterval(intervalId.current);
        };
    }, []);


    // Define status dropdown options
    const availableStatus = [
        { label: 'All', value: 'all' },
        { label: 'Uncompleted', value: 'uncompleted' },
        { label: 'Partly Completed', value: 'partlyCompleted' },
        { label: 'Completed', value: 'completed' }
    ];


    return (
        <>
            <div className={styling.status} hidden={!liveMode}>
                <div className={styling.badge} hidden={isLoading}>
                    <span>Last updated: </span>
                    <ReactTimeAgo date={new Date()} timeStyle="round"/>
                </div>

                <Spinner size="tiny" hidden={!isLoading}/>
            </div>

            <div className={styling.filters}>
                <div>
                    <h6>Filter by Location</h6>
                    <Dropdown
                        options={locations || []}
                        value={locations?.find(l => l.value === locationId) || null}
                        changeHandler={({ value }) => dropdownChangeHandler('locationId', value)}
                    />
                </div>

                <div>
                    <h6>Filter by Status</h6>
                    <Dropdown
                        options={availableStatus}
                        value={availableStatus.find(x => x.value === status)}
                        changeHandler={({ value }) => dropdownChangeHandler('status', value)}
                    />
                </div>
            </div>

            <ul className={styling.list} hidden={!orders.length || (isLoading && !liveMode)}>
                {orders.map(order => (
                    <li key={order._id}>
                        <Order {...order} showDetails={() => showOrderDetails(true, order)}/>
                    </li>
                ))}

                <li hidden={orders.length < 150}>
                    <div className={styling.notice}>
                        Showing the latest 150 orders. To see more orders, please adjust your search filters.
                    </div>
                </li>
            </ul>

            <Placeholder title="No Orders" hidden={orders.length || (isLoading && !liveMode)}>
                There are no orders that match the current filter. Change your filters or send your first order.
            </Placeholder>

            <Spinner hidden={!isLoading || liveMode}/>

            <Modal open={orderDetailsVisible}>
                <OrderDetails
                    orderDetails={orderDetails}
                    refetch={fetchOrders}
                    closeHandler={() => showOrderDetails(false)}
                />
            </Modal>
        </>
    );
};

export default Orders;