import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import Onboarding from 'components/onboarding/Onboarding';
import Spinner from 'components/UI/spinner/Spinner';
import Nav from '../nav/Nav';

import { useStore } from 'context/';
import utils from 'utils/';
import api from 'api/';

import styling from './Authenticated.module.scss';

const Authenticated = ({ children, history, location }) => {
    // State
    const [isLoading, setIsLoading] = useState(true);


    // Store
    const [store, dispatch] = useStore();


    /**
     * Fetches the user's data if they have still an active session.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchUserData = useCallback(async () => {
        try {
            const accessPass = localStorage.getItem('access-pass');
            const expirationTime = localStorage.getItem('expiration-time');

            if (!accessPass || !expirationTime || new Date(parseInt(expirationTime)) < new Date()) {
                history.push({ pathname: '/auth/sign-in', state: { redirectUrl: location.pathname } });
                return;
            }

            const res = await api.me();

            const update = {
                user: {
                    ...res.user,
                    organization: {
                        ...res.organization
                    }
                }
            };

            dispatch({ type: 'update', payload: update });

            utils.expirationHandler(history, expirationTime);

            document.title = 'iDo Dashboard | ' + res.user.email;

            setIsLoading(false);

        } catch (error) {
            console.error(error.message);
            localStorage.clear();
            history.push('/auth/sign-in');
        }
    }, [dispatch, history, location.pathname]);


    /**
     * Fetches the user's information if they are not saved in the store.
     */
    useEffect(() => {
        if (!store?.user?._id) {
            fetchUserData();
        } else if (isLoading) {
            setIsLoading(false);
        }
    }, [fetchUserData, isLoading, store?.user?._id]);


    // The component of the currently active route
    const component = (
        <>
            <Nav/>
            <main className={styling.container}>{children}</main>
            <Onboarding open={!store?.user?.organization?.slug}/>
        </>
    );


    return isLoading ? <Spinner/> : component;
};

export default withRouter(Authenticated);