import React, { useState, useEffect, useCallback } from 'react';

import Orders from 'components/orders/Orders';

import api from 'api/';

const LiveOrders = () => {
    // State
    const [locations, setLocations] = useState([]);


    /**
     * Fetches all locations and stores them in the local state.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchAllLocations = useCallback(async () => {
        try {
            const res = await api.getAllLocations();

            const locations = res.map(location => ({ label: location.name, value: location._id }));

            setLocations(locations);

        } catch (error) {
            console.error(error.message);
        }
    }, []);


    /**
     * Fetches all locations when the fetchAllLocations
     * function changes.
     */
    useEffect(fetchAllLocations, [fetchAllLocations]);


    return (
        <>
            <h1>Live Orders</h1>

            <Orders locations={locations} liveMode/>
        </>
    );
};

export default LiveOrders;