import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';

import styling from './Input.module.scss';

const Input = (props) => {
    const updatedProps = {
        ...props,
        type: props.type || 'text',
        'aria-label': props.name || ''
    };

    delete updatedProps.test;
    delete updatedProps.isInvalid;
    delete updatedProps.larger;
    delete updatedProps.isTextArea;

    let className = props.larger ? styling.larger : styling.input;

    if ((props.test && props.value && !props.test.test(props.value)) || props.isInvalid) {
        className = className + ' ' + styling.invalid;
    }

    let inputField = <input className={className} {...updatedProps} />;

    if (props.isTextArea) {
        className += ' ' + styling.textArea;
        inputField = <textarea className={className} {...updatedProps} />;
    }

    return (
        <label className={styling.regularLabel}>
            {props.label}
            {inputField}
            <span className={styling.caption} hidden={!props.caption}>{props.caption}</span>
        </label>
    );
};

class CreatableInput extends Component {
    state = {
        inputValue: ''
    };


    /**
     * Handles clearing all values and removing single values
     * @param _ {any} unused argument
     * @param action {string} the type of the change
     * @param removedValue {string} the value that was removed
     */
    changeHandler = (_, { action, removedValue }) => {
        if (action === 'clear') {
            this.props.changeHandler([]);
        }

        if (action === 'remove-value') {
            const updatedValues = (this.props.values || []).filter(v => v !== removedValue.value);
            this.props.changeHandler(updatedValues);
        }
    };


    /**
     * Handles input field changes.
     * @param inputValue {string} the value that was entered into the input field
     */
    inputChangeHandler = (inputValue) => {
        this.setState({ inputValue });
    };


    /**
     * When enter or tab is pressed, the current
     * value of the input field will be saved.
     * @param e {object} event object
     */
    keyDownHandler = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();

            this.props.changeHandler([...(this.props.values || []), this.state.inputValue]);
            this.setState({ inputValue: '' });
        }
    };


    render() {
        const values = (this.props.values || []).map(v => ({ label: v, value: v })) || [];

        return (
            <CreatableSelect
                components={{ DropdownIndicator: null }}
                inputValue={this.state.inputValue}
                onInputChange={this.inputChangeHandler}
                onKeyDown={this.keyDownHandler}
                onChange={this.changeHandler}
                placeholder="Type and hit enter..."
                value={values}
                menuIsOpen={false}
                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: '#106ee8'
                    }
                })}
                isDisabled={this.props.disabled || false}
                isClearable
                isMulti
            />
        );
    }
}

export default Input;
export { CreatableInput };