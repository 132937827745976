import React, { useState } from 'react';
import { IoTrashOutline, IoQrCode } from 'react-icons/io5';

import QRCode from 'components/UI/qrCode/QRCode';
import Button from 'components/UI/button/Button';
import Modal from 'components/UI/modal/Modal';

import styling from './Position.module.scss';

const Position = ({ name, url, id, deleteHandler }) => {
    const [showQRCode, setShowQRCode] = useState(false);

    return (
        <>
            <div className={styling.container} data-position-id={id}>
                <div className={styling.name}>{name}</div>

                <div className={styling.wrapper}>
                    <button className={styling.button} onClick={() => setShowQRCode(true)}>
                        <IoQrCode/>
                    </button>

                    <button className={styling.button} onClick={deleteHandler}>
                        <IoTrashOutline/>
                    </button>
                </div>
            </div>

            <Modal open={showQRCode}>
                <div className={styling.modal}>
                    <QRCode value={url + '?positionId=' + id} downloadable larger/>

                    <h1>{name}</h1>

                    <p>
                        This QR code is specifically for the position <b>{name}</b>. Scanning this QR code will preselect this position at
                        checkout.
                    </p>

                    <div className={styling.controls}>
                        <Button onClick={() => setShowQRCode(false)}>
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Position;