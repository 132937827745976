import config from 'config';

const _send = async (path, opts) => {
    const accessPass = localStorage.getItem('access-pass');

    const options = {
        ...opts,
        headers: {
            'Content-Type': 'application/json',
            ...(accessPass && { 'Authorization': 'Bearer ' + accessPass }),
            ...(opts.headers || {})
        },
        credentials: 'include'
    };

    const res = await fetch(config.connectivity.apiBaseUrl + path, options);

    const parsedResponse = await res.json();

    if (!parsedResponse.ok) {
        throw new Error(parsedResponse.message || 'failed to send request');
    }

    return parsedResponse.data || {};
};

const signUp = async (payload) => {
    const path = 'auth/sign-up?mode=cookie';

    const opts = {
        method: 'POST',
        body: JSON.stringify(payload)
    };

    return await _send(path, opts);
};

const signIn = async (payload) => {
    const path = 'auth/sign-in?mode=cookie';

    const opts = {
        method: 'POST',
        body: JSON.stringify(payload)
    };

    return await _send(path, opts);
};

const me = async () => {
    const path = 'auth/me';

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const createMenu = async (payload) => {
    const path = 'menus';

    const opts = {
        method: 'POST',
        body: JSON.stringify(payload)
    };

    return await _send(path, opts);
};

const getAllMenus = async () => {
    const path = 'menus';

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const getMenuById = async (menuId) => {
    const path = 'menus/' + menuId;

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const updateMenu = async (menuId, update) => {
    const path = 'menus/' + menuId;

    const opts = {
        method: 'PATCH',
        body: JSON.stringify(update)
    };

    return await _send(path, opts);
};

const setSlug = async (payload) => {
    const path = 'organizations/slug';

    const opts = {
        method: 'PATCH',
        body: JSON.stringify(payload)
    };

    return await _send(path, opts);
};

const deleteMenuById = async (id) => {
    const path = 'menus/' + id;

    const opts = {
        method: 'DELETE'
    };

    return await _send(path, opts);
};

const createLocation = async (payload) => {
    const path = 'locations';

    const opts = {
        method: 'POST',
        body: JSON.stringify(payload)
    };

    return await _send(path, opts);
};

const getAllLocations = async () => {
    const path = 'locations';

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const getLocationById = async (id) => {
    const path = 'locations/' + id;

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const addPosition = async (locationId, payload) => {
    const path = 'locations/' + locationId + '/positions';

    const opts = {
        method: 'POST',
        body: JSON.stringify(payload)
    };

    return await _send(path, opts);
};

const deletePosition = async (locationId, positionId) => {
    const path = 'locations/' + locationId + '/positions/' + positionId;

    const opts = {
        method: 'DELETE'
    };

    return await _send(path, opts);
};

const getOrdersByLocation = async (locationId, page, status = 'all') => {
    const path = 'orders/by-location/' + locationId + '?limit=150&page=' + page + '&status=' + status;

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const updateOrderStatus = async (orderId, status) => {
    const path = 'orders/' + orderId + '/status';

    const opts = {
        method: 'POST',
        body: JSON.stringify({ status })
    };

    return await _send(path, opts);
};

const deleteLocationById = async (locationId) => {
    const path = 'locations/' + locationId;

    const opts = {
        method: 'DELETE'
    };

    return await _send(path, opts);
};

const getDashboardData = async () => {
    const path = 'dashboard';

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const closeAccount = async () => {
    const path = 'organizations';

    const opts = {
        method: 'DELETE'
    };

    return await _send(path, opts);
};

const createEmployeeLogin = async (payload) => {
    const path = 'settings/employee-logins';

    const opts = {
        method: 'POST',
        body: JSON.stringify(payload)
    };

    return await _send(path, opts);
};

const getAllEmployeeLogins = async () => {
    const path = 'settings/employee-logins';

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const deleteEmployeeLogin = async (employeeLoginId) => {
    const path = 'settings/employee-logins/' + employeeLoginId;

    const opts = {
        method: 'DELETE'
    };

    return await _send(path, opts);
};

const changeEmployeeLoginPassword = async (employeeLoginId, password) => {
    const path = 'settings/employee-logins/' + employeeLoginId;

    const opts = {
        method: 'PATCH',
        body: JSON.stringify({ password })
    };

    return await _send(path, opts);
};

const getAllInvoices = async () => {
    const path = 'settings/invoices';

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const getInvoiceUrl = async (invoiceId) => {
    const path = 'settings/invoices/' + invoiceId + '/url';

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const resetPassword = async (email) => {
    const path = 'auth/reset-password';

    const opts = {
        method: 'POST',
        body: JSON.stringify({ email })
    };

    return await _send(path, opts);
};

const updatePassword = async (id, email, token, password) => {
    const path = 'auth/new-password';

    const opts = {
        method: 'POST',
        body: JSON.stringify({ id, email, token, password })
    };

    return await _send(path, opts);
};

const service = {
    signUp,
    signIn,
    me,
    createMenu,
    getAllMenus,
    getMenuById,
    updateMenu,
    setSlug,
    deleteMenuById,
    createLocation,
    getAllLocations,
    getLocationById,
    addPosition,
    deletePosition,
    getOrdersByLocation,
    updateOrderStatus,
    deleteLocationById,
    getDashboardData,
    closeAccount,
    createEmployeeLogin,
    getAllEmployeeLogins,
    deleteEmployeeLogin,
    changeEmployeeLoginPassword,
    getAllInvoices,
    getInvoiceUrl,
    resetPassword,
    updatePassword
};

export default service;