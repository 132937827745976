const menu = [
    {
        selector: '[data-tut="menu-step-1"]',
        content: 'Here you see an overview of your menu\'s settings such as logo and color.',
        position: 'bottom',
        stepInteraction: false
    },
    {
        selector: '[data-tut="menu-step-2"]',
        content: 'Click here to edit your menu\'s settings.',
        position: 'bottom',
        stepInteraction: false
    },
    {
        selector: '[data-tut="menu-step-3"]',
        content: 'Enter the name of your menu here.',
        position: 'bottom',
        stepInteraction: false
    },
    {
        selector: '[data-tut="menu-step-4"]',
        content: 'Choose the currency of your menu. It will automatically be applied to all prices.',
        position: 'bottom',
        stepInteraction: false
    },
    {
        selector: '[data-tut="menu-step-5"]',
        content: 'Choose the main color, logo and background of the menu.',
        position: 'bottom',
        stepInteraction: false
    },
    {
        selector: '[data-tut="menu-step-6"]',
        content: 'Below the setting you can create your menu. On the top level, you can add one or more categories, such as "Starters", to organize your offerings.',
        position: 'bottom',
        stepInteraction: false
    },
    {
        selector: '[data-tut="menu-step-7"]',
        content: 'Click the pen to edit the name and description of corresponding the category.',
        position: 'bottom',
        stepInteraction: false
    },
    {
        selector: '[data-tut="menu-step-8"]',
        content: 'Click to delete the element from the menu.',
        position: 'bottom',
        stepInteraction: false
    },
    {
        selector: '[data-tut="menu-step-9"]',
        content: 'Click the plus icon to add a sub-category or an item to the category.',
        position: 'bottom',
        stepInteraction: false
    },
    {
        selector: '[data-tut="menu-step-10"]',
        content: 'Click on the element to change its name, description and price.',
        position: 'bottom',
        stepInteraction: false
    },
    {
        selector: '[data-tut="menu-step-11"]',
        content: 'Click and drag the element to the desired position, if you want to move it.',
        position: 'bottom',
        stepInteraction: false
    }
];

const onboarding = [
    {
        selector: '[data-tut="onboarding-step-1"]',
        content: <><h6>Navigation</h6><p>To navigate in iDo, click here and select the desired page.</p></>,
        position: 'left',
        stepInteraction: false
    },
    {
        selector: '[data-tut="onboarding-step-2"]',
        content: <><h6>First Steps</h6><p>We recommend starting by creating your menus first and locations afterwards.</p></>,
        position: 'left',
        stepInteraction: false
    },
    {
        selector: '[data-tut="onboarding-step-3"]',
        content: <><h6>Create a Menu</h6><p>Get started now by clicking "Create" to add your first menu.</p></>,
        position: 'bottom',
        stepInteraction: true
    }
];

const tours = { menu, onboarding };

export default tours;