import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { useSnackbar } from 'react-simple-snackbar';
import { Link } from 'react-router-dom';

import Confirmation from 'components/UI/confirmation/Confirmation';
import Placeholder from 'components/UI/placeholder/Placeholder';
import Spinner from 'components/UI/spinner/Spinner';
import Button from 'components/UI/button/Button';
import QRCode from 'components/UI/qrCode/QRCode';

import Orders from 'components/orders/Orders';

import NewPosition from './newPosition/NewPosition';
import Position from './position/Position';

import config from 'config/';
import utils from 'utils/';
import api from 'api/';

import styling from './Location.module.scss';

const Location = ({ history, match }) => {
    // State
    const [{ location, stagedPositionToDelete, showNewPositionModal, isLoading }, setState] = useState({
        location: {},
        stagedPositionToDelete: '',
        showNewPositionModal: false,
        isLoading: true
    });


    // Refs
    const positionListRef = useRef({});


    // Hooks
    const [openSnackbar] = useSnackbar();


    /**
     * Fetches the current location.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchLocation = useCallback(async () => {
        try {
            const res = await api.getLocationById(match.params.id);

            await utils.sleep(250);

            setState(prevState => ({ ...prevState, location: res, isLoading: false }));

        } catch (error) {
            console.error(error.message);
            history.push('/locations');
        }
    }, [match.params.id, history]);


    /**
     * Toggles the visibility of the add new position modal.
     */
    const toggleNewPositionVisibility = () => {
        setState(prevState => ({ ...prevState, showNewPositionModal: !prevState.showNewPositionModal }));
    };


    /**
     * Adds a new position.
     * @param name {string} name of the position
     * @returns {Promise<void>}
     */
    const addPosition = async (name) => {
        try {
            const res = await api.addPosition(match.params.id, { name });

            await utils.sleep(150);

            setState(prevState => ({ ...prevState, location: res }));

            openSnackbar('Successfully created position ' + name);

        } catch (error) {
            console.error(error.message);
            openSnackbar('Failed to create position');
        }
    };


    /**
     * Deletes a position with the staged ID.
     * @returns {Promise<void>}
     */
    const deletePosition = async () => {
        try {
            const target = document.querySelector(`[data-position-id="${stagedPositionToDelete}"]`);

            if (target) {
                target.style.transform = 'translateX(2rem)';
                target.style.opacity = '0';
            }

            await utils.sleep(400);

            const updatedPositions = [...location.positions || []].filter((position => {
                return position._id !== stagedPositionToDelete;
            }));

            setState(prevState => ({
                ...prevState,
                location: {
                    ...prevState.location,
                    positions: updatedPositions
                }
            }));

            await api.deletePosition(match.params.id, stagedPositionToDelete);

            openSnackbar('Successfully delete position');

        } catch (error) {
            console.error(error.message);
            openSnackbar('Failed to delete position');
        }
    };


    /**
     * Stages or unstages a position to delete.
     * @param id {string} ID of the position that should be staged to be deleted
     */
    const stageDeletePosition = (id = '') => {
        setState(prevState => ({ ...prevState, stagedPositionToDelete: id }));
    };


    /**
     * Fetches the current location when the function
     * fetchLocation changes.
     */
    useEffect(() => {
        fetchLocation();
    }, [fetchLocation]);


    // Menu URL
    const url = `${config.connectivity.menuUrl}${location.organizationSlug}/${location.locationSlug}`;


    // Component
    const component = (
        <>
            <Link to="/locations" className={styling.back}>
                <IoArrowBackCircle/>
                <span>Back to all Locations</span>
            </Link>

            <div className={styling.header}>
                <QRCode value={url} downloadable larger/>

                <h1>{location.name}</h1>

                <a href={url} target="_blank" rel="noreferrer noopener">{url}</a>
            </div>

            <div className={styling.sectionTitle}>
                <h2>Order History</h2>
            </div>

            <Orders locations={[{ label: location.name || '', value: match.params.id }]}/>

            <div className={styling.sectionTitle}>
                <h2>Positions</h2>
            </div>

            <ul ref={positionListRef} hidden={!location?.positions?.length}>
                {(location?.positions || []).map(position => (
                    <li key={position._id}>
                        <Position
                            name={position.name}
                            url={url}
                            id={position._id}
                            deleteHandler={() => stageDeletePosition(position._id)}
                        />
                    </li>
                ))}

                <li className={styling.button}>
                    <Button onClick={toggleNewPositionVisibility}>Add New</Button>
                </li>
            </ul>

            <Placeholder
                title="No positions available"
                callToAction={toggleNewPositionVisibility}
                callToActionLabel="Create Position"
                hidden={location?.positions?.length}
            >
                Positions represent specific places at a location. In a restaurant, they probably represent tables. Add all available
                positions for this location. This is required to understand where orders need to be delivered to.
            </Placeholder>

            <NewPosition
                open={showNewPositionModal}
                closeHandler={toggleNewPositionVisibility}
                saveHandler={addPosition}
            />

            <Confirmation
                open={!!stagedPositionToDelete}
                title="Delete Position"
                message="Are you sure you want to permanently delete this position and all its orders?"
                cancelHandler={stageDeletePosition}
                confirmHandler={deletePosition}
            />
        </>
    );


    return isLoading ? <Spinner/> : component;
};

export default Location;