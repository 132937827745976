const config = {
    regex: {
        url: new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/),
        name: new RegExp(/^[a-zA-Z\s]*$/),
        email: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
        password: new RegExp(/^(?=.*[\d])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,15}$/),
        price: new RegExp(/^\d*(\.\d{0,2})?$/)
    },
    animation: {
        layoutTransition: {
            type: 'spring',
            damping: 35,
            stiffness: 400
        }
    },
    connectivity: {
        apiBaseUrl: (window.location.hostname === 'localhost') ? 'http://localhost:2971/' : 'https://service.i-do.io/',
        menuUrl: (window.location.hostname === 'localhost') ? 'http://localhost:3001/' : 'https://i-do.menu/'
    },
    translationOptions: [
        { label: 'Albanian', value: 'al' },
        { label: 'German', value: 'de' },
        { label: 'Danish', value: 'dk' },
        { label: 'Spanish', value: 'es' },
        { label: 'French', value: 'fr' },
        { label: 'English', value: 'en' },
        { label: 'Italian', value: 'it' },
        { label: 'Russian', value: 'ru' },
        { label: 'Swedish', value: 'se' }
    ],
    allergies: [
        { label: 'Gluten', value: 'gluten' },
        { label: 'Crustaceans', value: 'crustaceans' },
        { label: 'Eggs', value: 'eggs' },
        { label: 'Fish', value: 'fish' },
        { label: 'Peanuts', value: 'Peanuts' },
        { label: 'Soy', value: 'soy' },
        { label: 'Lactose', value: 'lactose' },
        { label: 'Nuts', value: 'nuts' },
        { label: 'Celery', value: 'celery' },
        { label: 'Mustard', value: 'mustard' },
        { label: 'Sesame', value: 'sesame' },
        { label: 'Sulfite', value: 'sulfite' },
        { label: 'Lupine', value: 'lupine' },
        { label: 'Molluscs', value: 'molluscs' }
    ],
    currencies: [
        {
            label: 'US Dollar (USD)',
            value: 'US Dollar (USD)',
            symbol: '$'
        },
        {
            label: 'Canadian Dollar (CAD)',
            value: 'Canadian Dollar (CAD)',
            symbol: 'CA$'
        },
        {
            label: 'Euro (EUR)',
            value: 'Euro (EUR)',
            symbol: '€'
        },
        {
            label: 'United Arab Emirates Dirham (AED)',
            value: 'United Arab Emirates Dirham (AED)',
            symbol: 'AED'
        },
        {
            label: 'Afghan Afghani (AFN)',
            value: 'Afghan Afghani (AFN)',
            symbol: 'Af'
        },
        {
            label: 'Albanian Lek (ALL)',
            value: 'Albanian Lek (ALL)',
            symbol: 'ALL'
        },
        {
            label: 'Armenian Dram (AMD)',
            value: 'Armenian Dram (AMD)',
            symbol: 'AMD'
        },
        {
            label: 'Argentine Peso (ARS)',
            value: 'Argentine Peso (ARS)',
            symbol: 'AR$'
        },
        {
            label: 'Australian Dollar (AUD)',
            value: 'Australian Dollar (AUD)',
            symbol: 'AU$'
        },
        {
            label: 'Azerbaijani Manat (AZN)',
            value: 'Azerbaijani Manat (AZN)',
            symbol: 'man.'
        },
        {
            label: 'Bosnia-Herzegovina Convertible Mark (BAM)',
            value: 'Bosnia-Herzegovina Convertible Mark (BAM)',
            symbol: 'KM'
        },
        {
            label: 'Bangladeshi Taka (BDT)',
            value: 'Bangladeshi Taka (BDT)',
            symbol: 'Tk'
        },
        {
            label: 'Bulgarian Lev (BGN)',
            value: 'Bulgarian Lev (BGN)',
            symbol: 'BGN'
        },
        {
            label: 'Bahraini Dinar (BHD)',
            value: 'Bahraini Dinar (BHD)',
            symbol: 'BD'
        },
        {
            label: 'Burundian Franc (BIF)',
            value: 'Burundian Franc (BIF)',
            symbol: 'FBu'
        },
        {
            label: 'Brunei Dollar (BND)',
            value: 'Brunei Dollar (BND)',
            symbol: 'BN$'
        },
        {
            label: 'Bolivian Boliviano (BOB)',
            value: 'Bolivian Boliviano (BOB)',
            symbol: 'Bs'
        },
        {
            label: 'Brazilian Real (BRL)',
            value: 'Brazilian Real (BRL)',
            symbol: 'R$'
        },
        {
            label: 'Botswanan Pula (BWP)',
            value: 'Botswanan Pula (BWP)',
            symbol: 'BWP'
        },
        {
            label: 'Belarusian Ruble (BYN)',
            value: 'Belarusian Ruble (BYN)',
            symbol: 'Br'
        },
        {
            label: 'Belize Dollar (BZD)',
            value: 'Belize Dollar (BZD)',
            symbol: 'BZ$'
        },
        {
            label: 'Congolese Franc (CDF)',
            value: 'Congolese Franc (CDF)',
            symbol: 'CDF'
        },
        {
            label: 'Swiss Franc (CHF)',
            value: 'Swiss Franc (CHF)',
            symbol: 'CHF'
        },
        {
            label: 'Chilean Peso (CLP)',
            value: 'Chilean Peso (CLP)',
            symbol: 'CL$'
        },
        {
            label: 'Chinese Yuan (CNY)',
            value: 'Chinese Yuan (CNY)',
            symbol: 'CN¥'
        },
        {
            label: 'Colombian Peso (COP)',
            value: 'Colombian Peso (COP)',
            symbol: 'CO$'
        },
        {
            label: 'Costa Rican Colón (CRC)',
            value: 'Costa Rican Colón (CRC)',
            symbol: '₡'
        },
        {
            label: 'Cape Verdean Escudo (CVE)',
            value: 'Cape Verdean Escudo (CVE)',
            symbol: 'CV$'
        },
        {
            label: 'Czech Republic Koruna (CZK)',
            value: 'Czech Republic Koruna (CZK)',
            symbol: 'Kč'
        },
        {
            label: 'Djiboutian Franc (DJF)',
            value: 'Djiboutian Franc (DJF)',
            symbol: 'Fdj'
        },
        {
            label: 'Danish Krone (DKK)',
            value: 'Danish Krone (DKK)',
            symbol: 'Dkr'
        },
        {
            label: 'Dominican Peso (DOP)',
            value: 'Dominican Peso (DOP)',
            symbol: 'RD$'
        },
        {
            label: 'Algerian Dinar (DZD)',
            value: 'Algerian Dinar (DZD)',
            symbol: 'DA'
        },
        {
            label: 'Estonian Kroon (EEK)',
            value: 'Estonian Kroon (EEK)',
            symbol: 'Ekr'
        },
        {
            label: 'Egyptian Pound (EGP)',
            value: 'Egyptian Pound (EGP)',
            symbol: 'EGP'
        },
        {
            label: 'Eritrean Nakfa (ERN)',
            value: 'Eritrean Nakfa (ERN)',
            symbol: 'Nfk'
        },
        {
            label: 'Ethiopian Birr (ETB)',
            value: 'Ethiopian Birr (ETB)',
            symbol: 'Br'
        },
        {
            label: 'British Pound Sterling (GBP)',
            value: 'British Pound Sterling (GBP)',
            symbol: '£'
        },
        {
            label: 'Georgian Lari (GEL)',
            value: 'Georgian Lari (GEL)',
            symbol: 'GEL'
        },
        {
            label: 'Ghanaian Cedi (GHS)',
            value: 'Ghanaian Cedi (GHS)',
            symbol: 'GH₵'
        },
        {
            label: 'Guinean Franc (GNF)',
            value: 'Guinean Franc (GNF)',
            symbol: 'FG'
        },
        {
            label: 'Guatemalan Quetzal (GTQ)',
            value: 'Guatemalan Quetzal (GTQ)',
            symbol: 'GTQ'
        },
        {
            label: 'Hong Kong Dollar (HKD)',
            value: 'Hong Kong Dollar (HKD)',
            symbol: 'HK$'
        },
        {
            label: 'Honduran Lempira (HNL)',
            value: 'Honduran Lempira (HNL)',
            symbol: 'HNL'
        },
        {
            label: 'Croatian Kuna (HRK)',
            value: 'Croatian Kuna (HRK)',
            symbol: 'kn'
        },
        {
            label: 'Hungarian Forint (HUF)',
            value: 'Hungarian Forint (HUF)',
            symbol: 'Ft'
        },
        {
            label: 'Indonesian Rupiah (IDR)',
            value: 'Indonesian Rupiah (IDR)',
            symbol: 'Rp'
        },
        {
            label: 'Israeli New Sheqel (ILS)',
            value: 'Israeli New Sheqel (ILS)',
            symbol: '₪'
        },
        {
            label: 'Indian Rupee (INR)',
            value: 'Indian Rupee (INR)',
            symbol: 'Rs'
        },
        {
            label: 'Iraqi Dinar (IQD)',
            value: 'Iraqi Dinar (IQD)',
            symbol: 'IQD'
        },
        {
            label: 'Iranian Rial (IRR)',
            value: 'Iranian Rial (IRR)',
            symbol: 'IRR'
        },
        {
            label: 'Icelandic Króna (ISK)',
            value: 'Icelandic Króna (ISK)',
            symbol: 'Ikr'
        },
        {
            label: 'Jamaican Dollar (JMD)',
            value: 'Jamaican Dollar (JMD)',
            symbol: 'J$'
        },
        {
            label: 'Jordanian Dinar (JOD)',
            value: 'Jordanian Dinar (JOD)',
            symbol: 'JD'
        },
        {
            label: 'Japanese Yen (JPY)',
            value: 'Japanese Yen (JPY)',
            symbol: '¥'
        },
        {
            label: 'Kenyan Shilling (KES)',
            value: 'Kenyan Shilling (KES)',
            symbol: 'Ksh'
        },
        {
            label: 'Cambodian Riel (KHR)',
            value: 'Cambodian Riel (KHR)',
            symbol: 'KHR'
        },
        {
            label: 'Comorian Franc (KMF)',
            value: 'Comorian Franc (KMF)',
            symbol: 'CF'
        },
        {
            label: 'South Korean Won (KRW)',
            value: 'South Korean Won (KRW)',
            symbol: '₩'
        },
        {
            label: 'Kuwaiti Dinar (KWD)',
            value: 'Kuwaiti Dinar (KWD)',
            symbol: 'KD'
        },
        {
            label: 'Kazakhstani Tenge (KZT)',
            value: 'Kazakhstani Tenge (KZT)',
            symbol: 'KZT'
        },
        {
            label: 'Lebanese Pound (LBP)',
            value: 'Lebanese Pound (LBP)',
            symbol: 'L.L.'
        },
        {
            label: 'Sri Lankan Rupee (LKR)',
            value: 'Sri Lankan Rupee (LKR)',
            symbol: 'SLRs'
        },
        {
            label: 'Lithuanian Litas (LTL)',
            value: 'Lithuanian Litas (LTL)',
            symbol: 'Lt'
        },
        {
            label: 'Latvian Lats (LVL)',
            value: 'Latvian Lats (LVL)',
            symbol: 'Ls'
        },
        {
            label: 'Libyan Dinar (LYD)',
            value: 'Libyan Dinar (LYD)',
            symbol: 'LD'
        },
        {
            label: 'Moroccan Dirham (MAD)',
            value: 'Moroccan Dirham (MAD)',
            symbol: 'MAD'
        },
        {
            label: 'Moldovan Leu (MDL)',
            value: 'Moldovan Leu (MDL)',
            symbol: 'MDL'
        },
        {
            label: 'Malagasy Ariary (MGA)',
            value: 'Malagasy Ariary (MGA)',
            symbol: 'MGA'
        },
        {
            label: 'Macedonian Denar (MKD)',
            value: 'Macedonian Denar (MKD)',
            symbol: 'MKD'
        },
        {
            label: 'Myanma Kyat (MMK)',
            value: 'Myanma Kyat (MMK)',
            symbol: 'MMK'
        },
        {
            label: 'Macanese Pataca (MOP)',
            value: 'Macanese Pataca (MOP)',
            symbol: 'MOP$'
        },
        {
            label: 'Mauritian Rupee (MUR)',
            value: 'Mauritian Rupee (MUR)',
            symbol: 'MURs'
        },
        {
            label: 'Mexican Peso (MXN)',
            value: 'Mexican Peso (MXN)',
            symbol: 'MX$'
        },
        {
            label: 'Malaysian Ringgit (MYR)',
            value: 'Malaysian Ringgit (MYR)',
            symbol: 'RM'
        },
        {
            label: 'Mozambican Metical (MZN)',
            value: 'Mozambican Metical (MZN)',
            symbol: 'MTn'
        },
        {
            label: 'Namibian Dollar (NAD)',
            value: 'Namibian Dollar (NAD)',
            symbol: 'N$'
        },
        {
            label: 'Nigerian Naira (NGN)',
            value: 'Nigerian Naira (NGN)',
            symbol: '₦'
        },
        {
            label: 'Nicaraguan Córdoba (NIO)',
            value: 'Nicaraguan Córdoba (NIO)',
            symbol: 'C$'
        },
        {
            label: 'Norwegian Krone (NOK)',
            value: 'Norwegian Krone (NOK)',
            symbol: 'Nkr'
        },
        {
            label: 'Nepalese Rupee (NPR)',
            value: 'Nepalese Rupee (NPR)',
            symbol: 'NPRs'
        },
        {
            label: 'New Zealand Dollar (NZD)',
            value: 'New Zealand Dollar (NZD)',
            symbol: 'NZ$'
        },
        {
            label: 'Omani Rial (OMR)',
            value: 'Omani Rial (OMR)',
            symbol: 'OMR'
        },
        {
            label: 'Panamanian Balboa (PAB)',
            value: 'Panamanian Balboa (PAB)',
            symbol: 'B/.'
        },
        {
            label: 'Peruvian Nuevo Sol (PEN)',
            value: 'Peruvian Nuevo Sol (PEN)',
            symbol: 'S/.'
        },
        {
            label: 'Philippine Peso (PHP)',
            value: 'Philippine Peso (PHP)',
            symbol: '₱'
        },
        {
            label: 'Pakistani Rupee (PKR)',
            value: 'Pakistani Rupee (PKR)',
            symbol: 'PKRs'
        },
        {
            label: 'Polish Zloty (PLN)',
            value: 'Polish Zloty (PLN)',
            symbol: 'zł'
        },
        {
            label: 'Paraguayan Guarani (PYG)',
            value: 'Paraguayan Guarani (PYG)',
            symbol: '₲'
        },
        {
            label: 'Qatari Rial (QAR)',
            value: 'Qatari Rial (QAR)',
            symbol: 'QR'
        },
        {
            label: 'Romanian Leu (RON)',
            value: 'Romanian Leu (RON)',
            symbol: 'RON'
        },
        {
            label: 'Serbian Dinar (RSD)',
            value: 'Serbian Dinar (RSD)',
            symbol: 'din.'
        },
        {
            label: 'Russian Ruble (RUB)',
            value: 'Russian Ruble (RUB)',
            symbol: 'RUB'
        },
        {
            label: 'Rwandan Franc (RWF)',
            value: 'Rwandan Franc (RWF)',
            symbol: 'RWF'
        },
        {
            label: 'Saudi Riyal (SAR)',
            value: 'Saudi Riyal (SAR)',
            symbol: 'SR'
        },
        {
            label: 'Sudanese Pound (SDG)',
            value: 'Sudanese Pound (SDG)',
            symbol: 'SDG'
        },
        {
            label: 'Swedish Krona (SEK)',
            value: 'Swedish Krona (SEK)',
            symbol: 'Skr'
        },
        {
            label: 'Singapore Dollar (SGD)',
            value: 'Singapore Dollar (SGD)',
            symbol: 'S$'
        },
        {
            label: 'Somali Shilling (SOS)',
            value: 'Somali Shilling (SOS)',
            symbol: 'Ssh'
        },
        {
            label: 'Syrian Pound (SYP)',
            value: 'Syrian Pound (SYP)',
            symbol: 'SY£'
        },
        {
            label: 'Thai Baht (THB)',
            value: 'Thai Baht (THB)',
            symbol: '฿'
        },
        {
            label: 'Tunisian Dinar (TND)',
            value: 'Tunisian Dinar (TND)',
            symbol: 'DT'
        },
        {
            label: 'Tongan Paʻanga (TOP)',
            value: 'Tongan Paʻanga (TOP)',
            symbol: 'T$'
        },
        {
            label: 'Turkish Lira (TRY)',
            value: 'Turkish Lira (TRY)',
            symbol: 'TL'
        },
        {
            label: 'Trinidad and Tobago Dollar (TTD)',
            value: 'Trinidad and Tobago Dollar (TTD)',
            symbol: 'TT$'
        },
        {
            label: 'New Taiwan Dollar (TWD)',
            value: 'New Taiwan Dollar (TWD)',
            symbol: 'NT$'
        },
        {
            label: 'Tanzanian Shilling (TZS)',
            value: 'Tanzanian Shilling (TZS)',
            symbol: 'TSh'
        },
        {
            label: 'Ukrainian Hryvnia (UAH)',
            value: 'Ukrainian Hryvnia (UAH)',
            symbol: '₴'
        },
        {
            label: 'Ugandan Shilling (UGX)',
            value: 'Ugandan Shilling (UGX)',
            symbol: 'USh'
        },
        {
            label: 'Uruguayan Peso (UYU)',
            value: 'Uruguayan Peso (UYU)',
            symbol: '$U'
        },
        {
            label: 'Uzbekistan Som (UZS)',
            value: 'Uzbekistan Som (UZS)',
            symbol: 'UZS'
        },
        {
            label: 'Venezuelan Bolívar (VEF)',
            value: 'Venezuelan Bolívar (VEF)',
            symbol: 'Bs.F.'
        },
        {
            label: 'Vietnamese Dong (VND)',
            value: 'Vietnamese Dong (VND)',
            symbol: '₫'
        },
        {
            label: 'CFA Franc BEAC (XAF)',
            value: 'CFA Franc BEAC (XAF)',
            symbol: 'FCFA'
        },
        {
            label: 'CFA Franc BCEAO (XOF)',
            value: 'CFA Franc BCEAO (XOF)',
            symbol: 'CFA'
        },
        {
            label: 'Yemeni Rial (YER)',
            value: 'Yemeni Rial (YER)',
            symbol: 'YR'
        },
        {
            label: 'South African Rand (ZAR)',
            value: 'South African Rand (ZAR)',
            symbol: 'R'
        },
        {
            label: 'Zambian Kwacha (ZMK)',
            value: 'Zambian Kwacha (ZMK)',
            symbol: 'ZK'
        },
        {
            label: 'Zimbabwean Dollar (ZWL)',
            value: 'Zimbabwean Dollar (ZWL)',
            symbol: 'ZWL$'
        }
    ]
};

export default config;