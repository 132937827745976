import React, { useState, useEffect, useCallback } from 'react';
import { IoRefreshCircle, IoTrashOutline } from 'react-icons/io5';
import { useSnackbar } from 'react-simple-snackbar';

import AddEmployeeLogin from './addEmployeeLogin/AddEmployeeLogin';
import Confirmation from 'components/UI/confirmation/Confirmation';
import Placeholder from 'components/UI/placeholder/Placeholder';
import ChangePassword from './changePassword/ChangePassword';
import Spinner from 'components/UI/spinner/Spinner';

import utils from 'utils/';
import api from 'api/';

import styling from './EmployeeLogins.module.scss';

const EmployeeLogins = () => {
    // States
    const [state, setState] = useState({
        employeeLogins: [],
        stagedEmployeeIdToDelete: '',
        stagedEmployeeLoginToUpdate: null,
        showAddEmployeeLoginModal: false,
        isLoading: true
    });

    const { employeeLogins, stagedEmployeeIdToDelete, stagedEmployeeLoginToUpdate, showAddEmployeeLoginModal, isLoading } = state;


    // Hooks
    const [openSnackbar] = useSnackbar();


    /**
     * Toggles the visibility of the close account confirmation.
     */
    const toggleShowAddEmployeeLoginModal = () => {
        setState(prevState => ({ ...prevState, showAddEmployeeLoginModal: !prevState.showAddEmployeeLoginModal }));
    };


    /**
     * Stages a given employee login ID to be deleted.
     * @param employeeLoginId {string} employee login ID that should be deleted.
     */
    const stageEmployeeLoginToDelete = (employeeLoginId = '') => {
        setState(prevState => ({ ...prevState, stagedEmployeeIdToDelete: employeeLoginId }));
    };


    /**
     * Stages an employee login to be updated.
     * @param employeeLogin {object} the employee login data that should be updated
     */
    const stageEmployeeLoginToUpdate = (employeeLogin = null) => {
        setState(prevState => ({ ...prevState, stagedEmployeeLoginToUpdate: employeeLogin }));
    };


    /**
     * Deletes the employee login with the staged employee login ID.
     * @returns {Promise<void>}
     */
    const deleteEmployeeLogin = async () => {
        try {
            if (!stagedEmployeeIdToDelete) {
                return;
            }

            const target = document.querySelector(`[data-employee-login-id="${stagedEmployeeIdToDelete}"]`);

            if (target) {
                target.style.transform = 'translateX(2rem)';
                target.style.opacity = '0';
            }

            await utils.sleep(400);

            const updatedEmployeeLogins = [...employeeLogins].filter(x => x._id !== stagedEmployeeIdToDelete);

            setState(prevState => ({ ...prevState, employeeLogins: updatedEmployeeLogins }));

            await api.deleteEmployeeLogin(stagedEmployeeIdToDelete);

            openSnackbar('Successfully deleted employee login');

        } catch (error) {
            console.error(error.message);
            openSnackbar(error.message);
        }
    };


    /**
     * Fetches all employee logins for the user's organization.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchEmployeeLogins = useCallback(async () => {
        try {
            const res = await api.getAllEmployeeLogins();

            await utils.sleep(250);

            setState(prevState => ({ ...prevState, employeeLogins: res, isLoading: false }));

        } catch (error) {
            console.error(error.message);
        }
    }, []);


    /**
     * Fetches all employee logins when fetchEmployeeLogins changes.
     */
    useEffect(fetchEmployeeLogins, [fetchEmployeeLogins]);


    return (
        <>
            <ul className={styling.list} hidden={!employeeLogins.length || isLoading}>
                {employeeLogins.map((employeeLogin) => (
                    <li
                        className={styling.employeeLogin}
                        data-employee-login-id={employeeLogin._id}
                        key={employeeLogin._id}
                    >
                        <div className={styling.name}>{employeeLogin.email}</div>

                        <div className={styling.wrapper}>
                            <button className={styling.button} onClick={() => stageEmployeeLoginToUpdate(employeeLogin)}>
                                <IoRefreshCircle/>
                            </button>

                            <button className={styling.button} onClick={() => stageEmployeeLoginToDelete(employeeLogin._id)}>
                                <IoTrashOutline/>
                            </button>
                        </div>
                    </li>
                ))}

                <li>
                    <button onClick={toggleShowAddEmployeeLoginModal}>Add Employee Login</button>
                </li>
            </ul>

            <Spinner hidden={!isLoading}/>

            <Placeholder
                title="No Employee Logins"
                callToAction={toggleShowAddEmployeeLoginModal}
                callToActionLabel="Add Employee Login"
                size="smaller"
                hidden={employeeLogins.length || isLoading}
            >
                Employee logins allow you to create accounts for your personnel. We recommend to create one login per employee.
            </Placeholder>

            <Confirmation
                open={!!stagedEmployeeIdToDelete}
                title="Delete Employee Login"
                message="Are you sure you want to permanently delete this employee login?"
                cancelHandler={() => stageEmployeeLoginToDelete('')}
                confirmHandler={deleteEmployeeLogin}
            />

            <AddEmployeeLogin
                open={showAddEmployeeLoginModal}
                refetch={fetchEmployeeLogins}
                closeHandler={toggleShowAddEmployeeLoginModal}
            />

            <ChangePassword
                open={!!stagedEmployeeLoginToUpdate}
                id={stagedEmployeeLoginToUpdate?._id}
                email={stagedEmployeeLoginToUpdate?.email}
                closeHandler={() => stageEmployeeLoginToUpdate()}
            />
        </>
    );
};

export default EmployeeLogins;