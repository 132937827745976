import React, { useState } from 'react';

import Spinner from 'components/UI/spinner/Spinner';
import Button from 'components/UI/button/Button';
import Input from 'components/UI/input/Input';
import Box from 'components/UI/box/Box';

import logo from 'assets/logos/ido.svg';

import utils from 'utils/';
import api from 'api/';

import styling from './ProvideSlug.module.scss';

const ProvideSlug = ({ completeHandler }) => {
    const [{ slug, status, isLoading }, setState] = useState({
        slug: '',
        status: '',
        isLoading: false
    });


    /**
     * Handles input field changes.
     * @param value {string} the value that was entered into the input field
     */
    const changeHandler = ({ target: { value } }) => {
        value = utils.removeSpecialChars(value);
        setState(prevState => ({ ...prevState, slug: value }));
    };


    /**
     * Checks if the slug is available and saves it if so.
     */
    const saveHandler = async () => {
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));

            const res = await api.setSlug({ slug });

            setTimeout(() => {
                if (res.status === 'unavailable') {
                    setState(prevState => ({ ...prevState, status: res.status, isLoading: false }));
                } else {
                    completeHandler();
                }
            }, 700);

        } catch (error) {
            console.error(error.message);
        }
    };


    return (
        <div className={styling.container}>
            <img src={logo} alt="logo"/>

            <h1>Welcome to iDo</h1>

            <p>Thank you for choosing iDo. Before you can get started, please choose your individual URL.</p>

            <Box type="warning" addTopMargin hidden={!status || status === 'available'}>
                The desired URL is already taken. Please choose another one and check its availability again.
            </Box>

            <div className={styling.inputWrapper}>
                <Input value={slug} onChange={changeHandler} larger/>
                <div className={styling.baseUrl}>https://i-do.menu/</div>
            </div>

            <div className={styling.saveWrapper}>
                {isLoading ? <Spinner size="small"/> : <Button onClick={saveHandler} disabled={!slug}>Check Availability Now</Button>}
            </div>
        </div>
    );
};

export default ProvideSlug;