import React from 'react';
import { IoAddCircle, IoPencil, IoTrashOutline } from 'react-icons/io5';

import styling from './Elements.module.scss';

const Category = ({ children, addTutNames, multiLanguageLabels, dragStart, stageUpdateElement, deleteHandler, addElement, path }) => (
    <div
        className={styling.category}
        onDragStart={dragStart}
        data-path={path}
        data-tut={addTutNames ? 'menu-step-6' : ''}
        draggable
    >
        <div className={styling.categoryHeader}>
            <div className={styling.title}>{multiLanguageLabels?.en?.name || ''}</div>

            <div className={styling.actions}>
                <button
                    className={styling.icon}
                    onClick={() => stageUpdateElement(path)}
                    data-tut={addTutNames ? 'menu-step-7' : ''}
                >
                    <IoPencil/>
                </button>

                <button
                    className={styling.icon}
                    onClick={() => deleteHandler(path)}
                    data-tut={addTutNames ? 'menu-step-8' : ''}
                >
                    <IoTrashOutline/>
                </button>
            </div>
        </div>

        {children}

        <button
            className={styling.add}
            onClick={() => addElement(path)}
            data-tut={addTutNames ? 'menu-step-9' : ''}
        >
            <IoAddCircle/>
        </button>
    </div>
);

export default Category;