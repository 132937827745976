import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar';

import Lottie from 'components/UI/lottie/Lottie';
import Button from 'components/UI/button/Button';

import { useStore } from 'context/';
import api from 'api/';

import styling from './Completed.module.scss';

const Completed = ({ history }) => {
    // Hooks
    const [openSnackbar] = useSnackbar();

    const { 1: dispatch } = useStore();


    /**
     * Fetches the updated organization
     * data and sets it in the context.
     * @returns {Promise<void>}
     */
    const completeOnboarding = async () => {
        try {
            const res = await api.me();

            const update = {
                user: {
                    ...res.user,
                    organization: {
                        ...res.organization
                    }
                }
            };

            dispatch({ type: 'update', payload: update });

            setTimeout(() => {
                openSnackbar('You have successfully set up your iDo account');
            }, 500);

            history.push({ pathname: '/menus', state: { showTour: true } });

        } catch (error) {
            console.error(error.message);
        }
    };


    return (
        <div className={styling.container}>
            <div className={styling.tick}>
                <Lottie type="tick" autoplay/>
            </div>

            <h1>Ready to go!</h1>

            <p>You are all set up and ready to go. Get started by creating your first menu.</p>

            <Button onClick={completeOnboarding}>Create your first Menu</Button>
        </div>
    );
};

export default withRouter(Completed);