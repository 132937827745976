import React, { useState, useEffect } from 'react';
import { IoPeopleCircleOutline } from 'react-icons/io5';

import Button from 'components/UI/button/Button';
import Input from 'components/UI/input/Input';
import Modal from 'components/UI/modal/Modal';
import Box from 'components/UI/box/Box';

import { useStore } from 'context/';
import utils from 'utils/';
import api from 'api/';

import styling from './AddEmployeeLogin.module.scss';

const AddEmployeeLogin = ({ open, refetch, closeHandler }) => {
    // State
    const [{ email, password, isUnavailable, isLoading }, setState] = useState({
        email: '',
        password: '',
        isUnavailable: false,
        isLoading: false
    });


    // Store
    const [store] = useStore();


    /**
     * Handles input field changes.
     * @param name {string} the name of the input field that was changed
     * @param value {string} the value that was entered into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        if (name === 'email') {
            value = utils.removeSpecialChars(value, '.');
        }
        setState(prevState => ({ ...prevState, [name]: value }));
    };


    /**
     * Creates an employee login with the desired email address.
     * @returns {Promise<void>}
     */
    const createEmployeeLogin = async () => {
        try {
            setState(prevState => ({ ...prevState, isUnavailable: false, isLoading: true }));

            const { status } = await api.createEmployeeLogin({ email, password });

            const isUnavailable = status === 'unavailable';

            if (!isUnavailable) {
                closeHandler();
                refetch();
                return;
            }

            setState(prevState => ({ ...prevState, isUnavailable, isLoading: false }));

        } catch (error) {
            console.error(error.message);
        }
    };


    /**
     * Resets the data when the component mounts.
     */
    useEffect(() => {
        if (open) {
            setState({
                email: '',
                password: '',
                isUnavailable: false,
                isLoading: false
            });
        }
    }, [open]);


    // Determine full email address
    const fullEmail = <b>{email}@{store?.user?.organization?.slug}.i-do.io</b>;


    return (
        <Modal open={open}>
            <div className={styling.container}>
                <div className={styling.icon}>
                    <IoPeopleCircleOutline/>
                </div>

                <h1>Add Employee Login</h1>

                <p>
                    Employee logins allow you to create an account for each of your employees. These accounts will not have access to
                    sensitive data such as your organization's billing settings.
                </p>

                <div className={styling.preview}>
                    Your employee login will be {fullEmail}.
                </div>

                <Box type="alert" hidden={!isUnavailable} addBottomMargin>
                    This email address is not available. Please choose another one and try again.
                </Box>

                <Input
                    value={email}
                    name="email"
                    label="Employee Name"
                    onChange={changeHandler}
                    placeholder="e.g. firstname_lastname"
                />

                <div className={styling.wrapper}>
                    <Input
                        value={password}
                        name="password"
                        label="Password"
                        onChange={changeHandler}
                        placeholder="Password"
                    />
                </div>

                <div className={styling.controls}>
                    <Button type="clear" onClick={closeHandler}>Cancel</Button>
                    <Button onClick={createEmployeeLogin} disabled={!email || !password || isLoading}>Create</Button>
                </div>
            </div>
        </Modal>
    );
};

export default AddEmployeeLogin;