import React from 'react';

import styling from './Flags.module.scss';

const Flags = ({ code, addLeftMargin }) => {
    let style = styling[(code || 'en').toLowerCase()];

    if (addLeftMargin) {
        style += ' ' + styling.addLeftMargin;
    }

    return <i className={style}/>;
};

export default Flags;