import React, { useState, useEffect } from 'react';
import get from 'lodash/get';

import Dropdown, { TranslationsDropdown } from 'components/UI/dropdown/Dropdown';
import Button from 'components/UI/button/Button';
import Toggle from 'components/UI/toggle/Toggle';
import Input from 'components/UI/input/Input';
import Modal from 'components/UI/modal/Modal';
import Box from 'components/UI/box/Box';

import { useMenuContext } from '../../context';
import config from 'config';
import utils from 'utils';

import styling from './ElementDetails.module.scss';

const ElementDetails = () => {
    // State
    const initialState = {
        type: '',
        price: '',
        multiLanguageLabels: {},
        currentLanguage: 'en',
        allergies: [],
        isVegetarian: false,
        isVegan: false,
        isNew: true
    };

    const [{
        type,
        price,
        multiLanguageLabels,
        currentLanguage,
        allergies,
        isVegetarian,
        isVegan,
        isNew
    }, setState] = useState(initialState);


    // Hooks
    const [{ items, stagedElementInstructions, currency }, dispatch] = useMenuContext();


    /**
     * Removes the target location of a new element or of
     * an element that was staged to be updated.
     */
    const unstageElement = () => {
        dispatch({ type: 'update', payload: { stagedElementInstructions: null } });
    };


    /**
     * Adds or updates the provided element at the staged position.
     * @param newElement {object} the element that should be added
     */
    const addOrUpdateElement = (newElement) => {
        if (!newElement || !stagedElementInstructions) {
            return;
        }

        const { location, index, isNew } = stagedElementInstructions;

        const itemsCopy = utils.copy(items);

        const target = location ? get(itemsCopy, location + '.items') : itemsCopy;

        if (isNew) {
            newElement.id = Date.now();

            if (index === '-1') {
                target.push(newElement);
            } else {
                target[index].items.push(newElement);
            }

        } else if (newElement.type === 'category') {
            target[index].multiLanguageLabels = newElement.multiLanguageLabels;

        } else {
            target[index] = { ...newElement, id: target[index].id };
        }

        dispatch({
            type: 'update',
            payload: {
                items: itemsCopy,
                stagedElementInstructions: null
            }
        });
    };


    /**
     * Constructs the new element and passes
     * it to the save handler.
     */
    const save = () => {
        const element = {
            type,
            multiLanguageLabels,
            price,
            ...(type === 'category' && { items: [] }),
            allergies,
            isVegetarian,
            isVegan
        };

        addOrUpdateElement(element);
        setTimeout(() => setState(initialState), 500);
    };


    /**
     * Resets the state and closes the modal.
     */
    const cancel = () => {
        setTimeout(() => setState(initialState), 500);
        unstageElement();
    };


    /**
     * Handles input field changes.
     * @param name {string} name of the input field that was changed
     * @param value {string} text that was entered
     */
    const changeHandler = ({ target: { name, value } }) => {
        if (name === 'price' && !config.regex.price.test(value)) {
            return;
        }

        if (name === 'price' && value === '.') {
            value = '0';
        }

        setState(prevState => ({ ...prevState, [name]: value }));
    };


    /**
     * Handles multi language label input field changes.
     * @param name {string} name of the input field that has changed
     * @param value {string} text that was entered
     */
    const multiLanguageLabelChangeHandler = ({ target: { name, value } }) => {
        const multiLanguageLabelsCopy = utils.copy(multiLanguageLabels);

        if (!multiLanguageLabelsCopy[currentLanguage]) {
            multiLanguageLabelsCopy[currentLanguage] = {};
        }

        multiLanguageLabelsCopy[currentLanguage][name] = value;

        setState(prevState => ({ ...prevState, multiLanguageLabels: multiLanguageLabelsCopy }));
    };


    /**
     * Handles type dropdown changes.
     * @param value {string} the value that was selected
     */
    const typeDropdownChangeHandler = ({ value }) => {
        setState(prevState => ({ ...prevState, type: value, multiLanguageLabels: {}, price: '' }));
    };


    /**
     * Handles language dropdown changes.
     * @param value {string} the value that was selected
     */
    const languageDropdownChangeHandler = ({ value }) => {
        setState(prevState => ({ ...prevState, currentLanguage: value }));
    };


    /**
     * Handles allergy dropdown changes.
     * @param values {array} selected allergies
     */
    const allergyDropdownHandler = (values) => {
        setState(prevState => ({ ...prevState, allergies: values.map(({ value }) => value) }));
    };


    /**
     * Handles toggle changes.
     * @param name {string} name of the toggle
     * @param checked {boolean} determines whether the toggle is checked or not
     */
    const toggleChangeHandler = ({ target: { name, checked } }) => {
        setState(prevState => ({ ...prevState, [name]: checked }));
    };


    /**
     * Saves the provided element data
     * to the local state if provided.
     */
    useEffect(() => {
        if (stagedElementInstructions?.elementData?.type) {
            setState({
                type: stagedElementInstructions.elementData.type || '',
                price: stagedElementInstructions.elementData.price || '',
                multiLanguageLabels: stagedElementInstructions.elementData.multiLanguageLabels || {},
                currentLanguage: 'en',
                allergies: stagedElementInstructions.elementData.allergies || [],
                isVegetarian: stagedElementInstructions.elementData.isVegetarian || false,
                isVegan: stagedElementInstructions.elementData.isVegan || false,
                isNew: false
            });
        }
    }, [stagedElementInstructions]);


    // Available elements
    const availableElements = [
        ...(stagedElementInstructions?.canBeCategory ? [{ label: 'Category', value: 'category' }] : []),
        ...(stagedElementInstructions?.canBeItem ? [{ label: 'Item', value: 'item' }] : [])
    ];


    // Determine currency symbol
    const currencySymbol = (config.currencies.find(x => x.value === currency) || {}).symbol || '';


    // Determine if the save button is disabled
    const isDisabled = !multiLanguageLabels?.en?.name || (type === 'item' && !price);


    return (
        <Modal open={!!stagedElementInstructions}>
            <h1>{isNew ? 'New Element' : 'Update Element'}</h1>

            <div hidden={!isNew}>
                <h6>Element Type</h6>

                <Dropdown
                    options={availableElements}
                    value={availableElements.find(x => x.value === type) || null}
                    changeHandler={typeDropdownChangeHandler}
                />
            </div>

            <div className={styling.topMargin}>
                <h6>Language</h6>

                <TranslationsDropdown
                    value={config.translationOptions.find(x => x.value === currentLanguage) || null}
                    changeHandler={languageDropdownChangeHandler}
                />
            </div>

            <div className={styling.box} hidden={multiLanguageLabels?.en?.name}>
                <Box type="warning">
                    <b>English name</b><br/>
                    All categories and items need to have at least an English name. Please add an English name and then your translations,
                    if desired, before saving your changes.
                </Box>
            </div>

            <div className={styling.row}>
                <div>
                    <Input
                        value={multiLanguageLabels[currentLanguage]?.name || ''}
                        name="name"
                        label="Name"
                        onChange={multiLanguageLabelChangeHandler}
                        placeholder="Name"
                    />
                </div>

                <div hidden={type !== 'item'}>
                    <Input
                        value={price}
                        name="price"
                        label="Price (required)"
                        onChange={changeHandler}
                        placeholder="Price"
                    />

                    <div className={styling.currency}>{currencySymbol}</div>
                </div>
            </div>

            <Input
                value={multiLanguageLabels[currentLanguage]?.description || ''}
                name="description"
                label="Description"
                onChange={multiLanguageLabelChangeHandler}
                placeholder="Description"
                isTextArea
            />

            <h6 className={styling.topMargin}>Allergies</h6>

            <Dropdown
                options={config.allergies}
                value={config.allergies.filter(x => allergies.includes(x.value)) || null}
                changeHandler={allergyDropdownHandler}
                isMulti
            />

            <h6 className={styling.topMargin} hidden={type !== 'item'}>Additional Options</h6>

            <div className={styling.wrapper} hidden={type !== 'item'}>
                <div className={styling.flex}>
                    <div>This item is vegetarian</div>
                    <Toggle
                        name="isVegetarian"
                        checked={isVegetarian}
                        onChange={toggleChangeHandler}
                        disabled={type !== 'item'}
                    />
                </div>

                <div className={styling.flex}>
                    <div>This item is vegan</div>
                    <Toggle
                        name="isVegan"
                        checked={isVegan}
                        onChange={toggleChangeHandler}
                        disabled={type !== 'item'}
                    />
                </div>
            </div>

            <div className={styling.controls}>
                <Button type="clear" onClick={cancel}>Cancel</Button>
                <Button onClick={save} disabled={isDisabled}>Save</Button>
            </div>
        </Modal>
    );
};

export default ElementDetails;