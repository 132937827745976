import React, { useState } from 'react';

import ProvideSlug from './provideSlug/ProvideSlug';
import Completed from './completed/Completed';
import Modal from 'components/UI/modal/Modal';

const Onboarding = ({ open }) => {
    const [isCompleted, setIsCompleted] = useState(false);

    let content = <ProvideSlug completeHandler={() => setIsCompleted(true)}/>;

    if (isCompleted) {
        content = <Completed/>;
    }

    return (
        <Modal open={open}>
            {content}
        </Modal>
    );
};

export default Onboarding;