import React from 'react';
import ReactTimeAgo from 'react-time-ago';

import styling from './Order.module.scss';

const Order = ({ number, status, showDetails, createdAt }) => {
    return (
        <div className={styling.container} onClick={showDetails}>
            <div className={styling.number}>#{number}</div>

            <div className={styling.wrapper}>
                <div className={styling[status]}>{status}</div>
            </div>

            <div className={styling.date}>
                <ReactTimeAgo date={new Date(createdAt)}/>
            </div>
        </div>
    );
};

export default Order;