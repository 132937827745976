import React, { useEffect, useState } from 'react';
import { readAndCompressImage } from 'browser-image-resizer';
import { IoBagHandleOutline } from 'react-icons/io5';

import ResponsiveColorPicker from 'components/UI/responsiveColorPicker/ResponsiveColorPicker';
import Dropdown from 'components/UI/dropdown/Dropdown';
import Button from 'components/UI/button/Button';
import Input from 'components/UI/input/Input';
import Modal from 'components/UI/modal/Modal';
import Box from 'components/UI/box/Box';

import { useMenuContext } from '../../context/';
import config from 'config/';

import styling from './Edit.module.scss';

const Edit = ({ open, closeHandler }) => {
    // State
    const [{ name, currency, logo, color, coverImage, fileSizeExceeded }, setState] = useState({
        name: '',
        currency: '',
        logo: '',
        color: null,
        coverImage: '',
        fileSizeExceeded: ''
    });


    // Hooks
    const [state, dispatch] = useMenuContext();


    /**
     * Handles input field changes.
     * @param name {string} name of the input field
     * @param value {string} the text that was entered
     */
    const changeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };


    /**
     * Handles currency dropdown changes.
     * @param value {string} the selected dropdown value
     */
    const dropdownChangeHandler = ({ value }) => {
        setState(prevState => ({ ...prevState, currency: value }));
    };


    /**
     * Handles color picker changes.
     * @param color {object} the selected color as hex rgb and hsv
     */
    const colorChangeHandler = (color) => {
        setState(prevState => ({ ...prevState, color }));
    };


    /**
     * Saves the data in the parent state.
     */
    const saveHandler = () => {
        dispatch({ type: 'update', payload: { name, currency, color, logo, coverImage } });
        closeHandler();
    };


    /**
     * Resets the current cover image image.
     */
    const resetCoverImage = () => {
        setState(prevState => ({ ...prevState, coverImage: '' }));
    };


    /**
     * Parses the selected logo as base64 and saves
     * it in the local state.
     */
    const selectImageHandler = (type) => {
        const input = document.createElement('input');

        input.type = 'file';
        input.accept = 'image/png, image/jpeg';

        input.onchange = async () => {
            try {
                if (input.files[0]) {
                    const file = input.files[0];

                    if ((type === 'coverImage') && 800000 < file.size) {
                        setState(prevState => ({ ...prevState, fileSizeExceeded: '800kb' }));
                        return;
                    }

                    if ((type === 'logo') && 400000 < file.size) {
                        setState(prevState => ({ ...prevState, fileSizeExceeded: '400kb' }));
                        return;
                    }

                    const reader = new FileReader();

                    reader.onloadend = () => {
                        setState(prevState => ({ ...prevState, [type]: reader.result, fileSizeExceeded: '' }));
                    };

                    const resized = await readAndCompressImage(file, {
                        quality: (type === 'logo') ? 1 : 0.85,
                        maxWidth: (type === 'logo') ? 400 : 1400,
                        maxHeight: (type === 'logo') ? 400 : 1000,
                        mimeType: (type === 'logo') ? 'image/png' : 'image/jpeg'
                    });

                    reader.readAsDataURL(resized);
                }
            } catch (error) {
                console.error(error.message);
            }
        };

        input.click();
    };


    /**
     * Saves the provided values in the local state.
     */
    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            name: state.name,
            currency: state.currency,
            logo: state.logo,
            color: state.color,
            coverImage: state.coverImage
        }));

    }, [state.name, state.currency, state.logo, state.color, state.coverImage]);


    return (
        <Modal open={open || !state.name}>
            <h1>Menu Settings</h1>

            <div className={styling.row}>
                <div data-tut="menu-step-3">
                    <Input
                        value={name}
                        name="name"
                        onChange={changeHandler}
                        label="Menu Name (required)"
                        placeholder="e.g. Pool Menu"
                        larger
                    />
                </div>

                <div data-tut="menu-step-4">
                    <h6>Currency</h6>

                    <Dropdown
                        value={config.currencies.find(x => x.value === currency)}
                        changeHandler={dropdownChangeHandler}
                        options={config.currencies}
                        searchable
                    />
                </div>
            </div>

            <Box type="alert" hidden={!fileSizeExceeded}>
                <b>File too large</b><br/>
                The provided file is too large. Make sure the file size does not exceed {fileSizeExceeded}. Please reduce the file size and
                select the file again. This is necessary to ensure quick loading times of your digital menu.
            </Box>

            <div className={styling.row} data-tut="menu-step-5">
                <div>
                    <h6>Logo</h6>

                    <div className={styling.preview}>
                        <div className={styling.bar} style={{ backgroundColor: color?.hex || '#000' }} hidden={!logo}>
                            <div className={styling.logo}>
                                <img src={logo} alt="logo preview" hidden={!logo}/>
                            </div>

                            <IoBagHandleOutline/>
                        </div>

                        <div className={styling.items} hidden={!logo || coverImage}>
                            <div/>
                            <div/>
                        </div>

                        <img
                            src={coverImage}
                            className={styling.coverImage}
                            alt="cover"
                            hidden={!coverImage || !logo}
                        />

                        <div className={styling.overlay} onClick={resetCoverImage} hidden={!coverImage}>
                            Click to remove cover image
                        </div>

                        <div className={styling.wrapper} hidden={!logo}>
                            <button onClick={() => selectImageHandler('logo')}>
                                Change Logo
                            </button>

                            <button onClick={() => selectImageHandler('coverImage')}>
                                Change Cover Image
                            </button>
                        </div>

                        <div
                            className={styling.placeholder}
                            onClick={() => selectImageHandler('logo')}
                            hidden={logo}
                        >
                            Click to add logo
                        </div>
                    </div>
                </div>

                <div>
                    <h6>Main Color</h6>

                    <div className={styling.colorPicker}>
                        <ResponsiveColorPicker
                            color={color}
                            updateColor={colorChangeHandler}
                        />
                    </div>
                </div>
            </div>

            <div className={styling.controls}>
                <Button type="clear" onClick={closeHandler} hidden={!name}>Cancel</Button>
                <Button onClick={saveHandler} disabled={!name}>Save</Button>
            </div>
        </Modal>
    );
};

export default Edit;