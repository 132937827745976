import React, { useEffect, useRef } from 'react';

import styling from './Lottie.module.scss';

const Lottie = ({ loop = false, autoplay = false, type }) => {
    const ref = useRef({});

    /**
     * Renders the animation in the container and destroys
     * it when the component is unmounted.
     */
    useEffect(() => {
        let animation;

        const paths = {
            tick: '/lotties/tick.json'
        };

        if (window.lottie) {

            animation = window.lottie.loadAnimation({
                container: ref.current,
                renderer: 'svg',
                loop,
                autoplay,
                path: window.origin + paths[type]
            });

            return () => {
                animation && animation.destroy();
            };
        }
    }, [loop, autoplay, type]);

    return <div className={styling.container} ref={ref}/>;
};

export default Lottie;