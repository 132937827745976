import React from 'react';

import Button from '../button/Button';
import Modal from '../modal/Modal';

import styling from './Confirmation.module.scss';

const Confirmation = ({ open, title, message, confirmHandler, label, cancelHandler }) => {

    /**
     * Closes the confirmation modal and calls the confirm
     * handler with a delay so that the modal closes
     * before the confirm handler is executed.
     */
    const confirm = () => {
        cancelHandler();
        setTimeout(confirmHandler, 200);
    };

    return (
        <Modal open={open} size="small">
            <h2>{title || 'Please confirm'}</h2>

            <p className={styling.message}>{message}</p>

            <div className={styling.controls}>
                <Button onClick={() => cancelHandler()} type="clear">Cancel</Button>
                <Button onClick={confirm}>{label || 'Confirm'}</Button>
            </div>
        </Modal>
    );
};

export default Confirmation;