import React, { useState, useEffect, useCallback } from 'react';
import { IoDocument } from 'react-icons/io5';

import Placeholder from 'components/UI/placeholder/Placeholder';
import Spinner from 'components/UI/spinner/Spinner';

import utils from 'utils/';
import api from 'api/';

import styling from './Invoice.module.scss';

const Invoices = () => {
    // State
    const [{ invoices, isLoading }, setState] = useState({
        invoices: [],
        isLoading: true
    });


    /**
     * Fetches all invoices.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchInvoices = useCallback(async () => {
        try {
            const invoices = await api.getAllInvoices();

            setState(prevState => ({ ...prevState, invoices, isLoading: false }));

        } catch (error) {
            console.error(error.message);
        }
    }, []);


    /**
     * Opens an invoice with the given invoice ID.
     * @param invoiceId {string} the ID of the invoice
     * @returns {Promise<void>}
     */
    const openInvoice = async (invoiceId) => {
        try {
            const { url } = await api.getInvoiceUrl(invoiceId);

            const a = document.createElement('a');

            a.setAttribute('href', url);
            a.setAttribute('target', '_blank');
            a.click();

        } catch (error) {
            console.error(error.message);
        }
    };


    /**
     * Fetches all invoices when the fetchInvoice function changes.
     */
    useEffect(fetchInvoices, [fetchInvoices]);


    return (
        <div className={styling.wrapper}>
            <ul className={styling.list} hidden={isLoading || !invoices.length}>
                {(invoices || []).map(invoice => (
                    <li className={styling.invoice} onClick={() => openInvoice(invoice._id)} key={invoice._id}>
                        <div className={styling.icon}>
                            <IoDocument/>
                        </div>

                        <div>
                            <div className={styling.number}>
                                #{invoice.invoiceNumber}
                                <div className={invoice.isPaid ? styling.paid : styling.unpaid}/>
                                <span>{invoice.isPaid ? 'paid' : 'unpaid'}</span>
                            </div>

                            <div className={styling.timestamp}>
                                Created: {utils.formatDate(new Date(invoice.createdAt))}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            <Placeholder title="No Invoices available" hidden={isLoading || invoices.length}>
                All your invoices will appear here.
            </Placeholder>

            <Spinner hidden={!isLoading}/>
        </div>
    );
};

export default Invoices;