import React from 'react';

import styling from './Spinner.module.scss';

const Spinner = ({ size, hidden }) => (
    <div className={styling[size] || styling.container} hidden={hidden}>
        <div className={styling.spinner}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
);

export default Spinner;