import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { IoLocationSharp } from 'react-icons/io5';

import Dropdown from 'components/UI/dropdown/Dropdown';
import Spinner from 'components/UI/spinner/Spinner';
import Button from 'components/UI/button/Button';
import Input from 'components/UI/input/Input';
import Modal from 'components/UI/modal/Modal';
import Box from 'components/UI/box/Box';

import config from 'config/';
import utils from 'utils/';
import api from 'api/';

import styling from './NewLocation.module.scss';

const NewLocation = ({ open, fetchLocations, close }) => {
    // States
    const [{ menus, name, menu, status, isLoading }, setState] = useState({
        menus: [],
        name: '',
        menu: null,
        status: '',
        isLoading: true
    });


    // Hooks
    const [openSnackbar] = useSnackbar();


    /**
     * Fetches all menus of the user's organization.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchMenus = useCallback(async () => {
        try {
            const res = await api.getAllMenus();

            const menus = (res || []).map(menu => ({ value: menu.name, label: menu.name, id: menu._id }));

            await utils.sleep(500);

            setState(prevState => ({ ...prevState, menus, isLoading: false }));

        } catch (error) {
            console.error(error.message);
        }
    }, []);


    /**
     * Creates a location with the desired name and menu.
     * @returns {Promise<void>}
     */
    const createLocation = async () => {
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));

            const res = await api.createLocation({ name, menuId: menu });

            await utils.sleep(400);

            if (res.status && res.status === 'unavailable') {
                setState(prevState => ({ ...prevState, status: 'unavailable', isLoading: false }));
            } else {
                fetchLocations();
                close();
            }

        } catch (error) {
            console.error(error.message);
            openSnackbar(error.message);
        }
    };


    /**
     * Handles input field changes.
     * @param value {string} the value that was entered
     */
    const changeHandler = ({ target: { value } }) => {
        setState(prevState => ({ ...prevState, name: value }));
    };


    /**
     * Handles dropdown changes.
     * @param id {string} the ID of the menu that was selected
     */
    const dropdownChangeHandler = ({ id }) => {
        setState(prevState => ({ ...prevState, menu: id }));
    };


    /**
     * Fetches all menus when the fetchMenus
     * function updates.
     */
    useEffect(() => {
        fetchMenus();
    }, [fetchMenus]);


    /**
     * Resets the state when the modal opens.
     */
    useEffect(() => {
        if (open) {
            setState(prevState => ({
                ...prevState,
                name: '',
                menu: null,
                status: '',
                isLoading: false
            }));
        }
    }, [open]);


    return (
        <Modal open={open}>
            <div className={styling.container} hidden={isLoading}>
                <div className={styling.pin}>
                    <IoLocationSharp/>
                </div>

                <h1>Add Location</h1>

                <p>
                    Create a location by giving it a unique name and selecting a menu, which you offer there. If you for example run a
                    hotel, you might create locations such as "Pool Bar" or "Lobby".
                </p>

                <Box type="warning" hidden={status !== 'unavailable'} addTopMargin>
                    The provided name for the new location is already in use but location names have to be unique. Please choose another
                    name and try again.
                </Box>

                <div className={styling.row}>
                    <Input
                        value={name}
                        onChange={changeHandler}
                        label="Name"
                        placeholder="e.g. Pool Bar"
                        test={config.regex.name}
                    />
                </div>

                <div className={styling.row}>
                    <h6>Menu offered here</h6>

                    <Dropdown
                        options={menus}
                        changeHandler={dropdownChangeHandler}
                        value={menu ? menus.find(x => x.id === menu) : null}
                    />
                </div>

                <div className={styling.controls}>
                    <Button type="clear" onClick={close}>Cancel</Button>
                    <Button onClick={createLocation} disabled={!name || !menu}>Create</Button>
                </div>
            </div>

            <Spinner hidden={!isLoading}/>
        </Modal>
    );
};

export default NewLocation;