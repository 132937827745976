import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Authenticated from './components/layout/authenticated/Authenticated';
import LiveOrders from './components/liveOrders/LiveOrders';
import Locations from './components/locations/Locations';
import Dashboard from './components/dashboard/Dashboard';
import Location from './components/location/Location';
import Settings from './components/settings/Settings';
import Menus from './components/menus/Menus';
import Auth from './components/auth/Auth';
import Menu from './components/menu/Menu';

const App = () => {
    return (
        <Switch>
            <Route path="/auth/:mode" component={Auth}/>

            <Authenticated>
                <Route path="/" component={Dashboard} exact/>
                <Route path="/menus" component={Menus} exact/>
                <Route path="/menus/new" component={Menu} exact/>
                <Route path="/menus/edit/:id" component={Menu} exact/>
                <Route path="/locations" component={Locations} exact/>
                <Route path="/locations/:id" component={Location} exact/>
                <Route path="/orders" component={LiveOrders} exact/>
                <Route path="/settings" component={Settings} exact/>
            </Authenticated>
        </Switch>
    );
};

export default App;