import React, { useState, useEffect, useCallback } from 'react';
import { IoTrashOutline, IoGlobe } from 'react-icons/io5';
import { useSnackbar } from 'react-simple-snackbar';
import ReactTimeAgo from 'react-time-ago';
import { Link } from 'react-router-dom';
import Tour from 'reactour';

import Confirmation from 'components/UI/confirmation/Confirmation';
import Placeholder from 'components/UI/placeholder/Placeholder';
import Spinner from 'components/UI/spinner/Spinner';
import Button from 'components/UI/button/Button';

import config from 'config/';
import utils from 'utils/';
import tours from 'tours';
import api from 'api/';

import styling from './Menus.module.scss';

const Menus = ({ history }) => {
    // States
    const [{ menus, menuIdToDelete, showTour, isLoading }, setState] = useState({
        menus: [],
        menuIdToDelete: '',
        showTour: false,
        isLoading: true
    });


    // Hooks
    const [openSnackbar] = useSnackbar();


    /**
     * Fetches all menus and saves them to the state.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchAllMenus = useCallback(async () => {
        try {
            const res = await api.getAllMenus();

            await utils.sleep(400);

            setState(prevState => ({ ...prevState, menus: res, isLoading: false }));

        } catch (error) {
            console.error(error.message);
        }
    }, []);


    /**
     * Stages a given menu ID to delete.
     * @param id {string} ID of the menu to be deleted
     */
    const stageMenuIdToDelete = (id = '') => {
        setState(prevState => ({ ...prevState, menuIdToDelete: id }));
    };


    /**
     * Closes the tour.
     */
    const closeTour = () => {
        setState(prevState => ({ ...prevState, showTour: false }));
    };


    /**
     * Deletes the menu with the staged menu ID.
     * @returns {Promise<void>}
     */
    const deleteMenu = async () => {
        try {
            if (!menuIdToDelete) {
                return;
            }

            const menu = document.querySelector(`[data-menu-id="${menuIdToDelete}"]`);

            menu.style.transform = 'translateX(2rem)';
            menu.style.opacity = '0';

            await utils.sleep(450);

            const menusCopy = utils.copy(menus);
            const updatedMenus = menusCopy.filter(m => m._id !== menuIdToDelete);

            setState(prevState => ({ ...prevState, menus: updatedMenus }));

            await api.deleteMenuById(menuIdToDelete);

            openSnackbar('Menu has been deleted successfully');

        } catch (error) {
            console.error(error.message);
            openSnackbar('Failed to delete menu');
        }
    };


    /**
     * Fetches all menus.
     */
    useEffect(() => {
        if (history.location.state?.showTour) {
            setTimeout(() => {
                setState(prevState => ({ ...prevState, showTour: true }));
            }, 2000);
        }

        fetchAllMenus();
    }, [fetchAllMenus, history.location.state?.showTour]);


    // All menus
    const list = (
        <ul className={styling.list} hidden={!menus.length}>
            {(menus || []).map((menu, i) => (
                <li
                    className={styling.menu}
                    data-menu-id={menu._id}
                    style={{ animationDelay: i * 0.15 + 's' }}
                    key={menu._id}
                >
                    <Link className={styling.wrapper} to={'/menus/edit/' + menu._id}>
                        <div className={styling.box}>
                            <div className={styling.title}>{menu.name}</div>
                            <div className={styling.subtitle}>Name</div>
                        </div>

                        <div className={styling.box}>
                            <div className={styling.title}>You</div>
                            <div className={styling.subtitle}>Creator</div>
                        </div>

                        <div className={styling.box}>
                            <div className={styling.title}>
                                <ReactTimeAgo date={new Date(menu.createdAt)}/>
                            </div>

                            <div className={styling.subtitle}>Created</div>
                        </div>
                    </Link>

                    <div className={styling.actions}>
                        <a
                            href={config.connectivity.menuUrl + 'preview/' + menu._id}
                            className={styling.action}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IoGlobe/>
                        </a>

                        <button className={styling.action} onClick={() => stageMenuIdToDelete(menu._id)}>
                            <IoTrashOutline/>
                        </button>
                    </div>
                </li>
            ))}
        </ul>
    );


    return (
        <>
            <div className={styling.header}>
                <h1>Menus</h1>
                <Button data-tut="onboarding-step-3" onClick={() => history.push('/menus/new')}>Create</Button>
            </div>

            {isLoading ? <Spinner/> : list}

            <Placeholder
                title="You don't have any menus yet"
                callToAction={() => history.push('/menus/new')}
                hidden={isLoading || menus.length}
            >
                Create a menu with all the dishes and beverages you offer your guest. You can create multiple menus if you have different
                menus for different locations.
            </Placeholder>

            <Confirmation
                open={!!menuIdToDelete}
                title="Delete Menu"
                message="Are you sure you want to delete this menu? This is permanent and cannot be undone."
                cancelHandler={stageMenuIdToDelete}
                confirmHandler={deleteMenu}
            />

            <Tour
                isOpen={showTour}
                steps={tours.onboarding}
                rounded={10}
                onRequestClose={closeTour}
                accentColor={utils.getCSSVariable('--color-1')}
                badgeContent={(c, t) => c + '/' + t}
                getCurrentStep={(step) => {
                    document.querySelector('[data-tut="onboarding-step-2"]').hidden = (step !== 1);
                }}
                startAt={0}
            />
        </>
    );
};

export default Menus;