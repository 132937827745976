import React, { useEffect, useState } from 'react';

import Button from 'components/UI/button/Button';
import Input from 'components/UI/input/Input';
import Modal from 'components/UI/modal/Modal';
import Box from 'components/UI/box/Box';

import styling from './NewPosition.module.scss';

const NewPosition = ({ open, closeHandler, saveHandler }) => {
    const [name, setName] = useState('');


    /**
     * Creates a new position and closes the modal.
     */
    const create = () => {
        saveHandler(name);
        closeHandler();
    };


    /**
     * Resets the name when the modal is opened.
     */
    useEffect(() => {
        if (open) {
            setName('');
        }
    }, [open]);


    return (
        <Modal open={open}>
            <h1>New Position</h1>

            <Box type="info" addBottomMargin>
                We recommend choosing unique names for your positions, which help you identifying them quickly. If you, for example, run a
                beach club you might want to call your positions "Sunbed 1", "Sunbed 2" and so on.
            </Box>

            <Input
                value={name}
                label="Name"
                onChange={({ target }) => setName(target.value)}
                placeholder="e.g. Sunbed 1"
            />

            <div className={styling.buttonRow}>
                <Button type="clear" onClick={closeHandler}>Cancel</Button>
                <Button onClick={create} disabled={!name}>Create</Button>
            </div>
        </Modal>
    );
};

export default NewPosition;