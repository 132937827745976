import React, { useState, useEffect } from 'react';
import ReactTimeAgo from 'react-time-ago';

import Checkbox from 'components/UI/checkbox/Checkbox';
import Table from 'components/UI/table/Table';
import Button from 'components/UI/button/Button';

import config from 'config/';
import utils from 'utils/';
import api from 'api/';

import styling from './OrderDetails.module.scss';

const OrderDetails = ({ orderDetails, refetch, closeHandler }) => {
    // State
    const [order, setOrder] = useState({});


    /**
     * Toggles the status of the item with the given index.
     * @param index {number} the index of the item whose status should be toggled
     */
    const toggleStatus = (index) => {
        try {
            setOrder(prevOrder => {
                const orderCopy = utils.copy(prevOrder);
                orderCopy.items[index].isCompleted = !orderCopy.items[index].isCompleted;
                return orderCopy;
            });

        } catch (error) {
            console.error(error.message);
        }
    };


    /**
     * Saves changes.
     * @returns {Promise<void>}
     */
    const saveHandler = async () => {
        try {
            const status = order.items.map(item => item.isCompleted);

            closeHandler();

            await api.updateOrderStatus(order._id, status);

            await utils.sleep(250);

            refetch();

        } catch (error) {
            console.error(error.message);
        }
    };


    /**
     * Sets the provided order details to the local state.
     */
    useEffect(() => {
        if (orderDetails) {
            setOrder(orderDetails);
        }
    }, [orderDetails]);


    // Determine position name of the current order
    const positionName = order.locationId?.positions?.find(p => p._id === order.positionId)?.name || '';


    // Determine currency symbol of current order
    const currencySymbol = config.currencies.find(c => c.value === order.currency)?.symbol || '';


    // Determine rows
    const rows = (order.items || []).map((item, i) => [
        { value: <Checkbox checked={item.isCompleted} id={i.toString()} changeHandler={() => toggleStatus(i)}/> },
        { value: item.amount, styles: ['centered'] },
        { value: item.name, styles: ['stretched'] },
        { value: item.price?.toFixed(2) + ' ' + currencySymbol, styles: ['noWrap', 'alignRight'] }
    ]);

    rows.push([
        { value: '' },
        { value: '' },
        { value: <b>Tips</b> },
        { value: <b>{order?.tip?.toFixed(2)} {currencySymbol}</b>, styles: ['noWrap', 'alignRight'] }
    ]);

    rows.push([
        { value: '' },
        { value: '' },
        { value: <b>Total</b> },
        { value: <b>{order?.total?.toFixed(2)} {currencySymbol}</b>, styles: ['noWrap', 'alignRight'] }
    ]);


    return (
        <div>
            <div className={styling[order.status]}>{order.status}</div>
            <h1>Order #{order.number}</h1>

            <div className={styling.row}>
                <div>
                    <div className={styling.title}>{order.locationId?.name}</div>
                    <div className={styling.caption}>Location</div>
                </div>

                <div>
                    <div className={styling.title}>{positionName}</div>
                    <div className={styling.caption}>Position</div>
                </div>

                <div>
                    <div className={styling.title}>{order.total?.toFixed(2)} {currencySymbol}</div>
                    <div className={styling.caption}>Total</div>
                </div>

                <div>
                    <div className={styling.title}>
                        <ReactTimeAgo date={order.createdAt ? new Date(order.createdAt) : new Date()}/>
                    </div>
                    <div className={styling.caption}>Received</div>
                </div>
            </div>

            <Table
                headers={[
                    { value: '' },
                    { value: 'QTY' },
                    { value: 'Name', stretched: true },
                    { value: 'Price' }
                ]}
                rows={rows}
            />

            <div className={styling.controls}>
                <Button type="clear" onClick={closeHandler}>Close</Button>
                <Button onClick={saveHandler}>Save</Button>
            </div>
        </div>
    );
};

export default OrderDetails;