import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { IoTrashOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import Confirmation from 'components/UI/confirmation/Confirmation';
import Placeholder from 'components/UI/placeholder/Placeholder';
import NewLocation from './newLocation/NewLocation';
import Spinner from 'components/UI/spinner/Spinner';
import Button from 'components/UI/button/Button';

import utils from 'utils/';
import api from 'api/';

import styling from './Locations.module.scss';

const Locations = () => {
    // State
    const [{ locations, locationIdToDelete, addNewLocationVisible, isLoading }, setState] = useState({
        locations: [],
        locationIdToDelete: '',
        addNewLocationVisible: false,
        isLoading: true
    });


    // Hooks
    const [openSnackbar] = useSnackbar();


    /**
     * Toggles the visibility of the add new location modal.
     */
    const toggleAddNewLocationVisibility = () => {
        setState(prevState => ({ ...prevState, addNewLocationVisible: !prevState.addNewLocationVisible }));
    };


    /**
     * Stages a given location ID to delete.
     * @param id {string} ID of the location to be deleted
     */
    const stageLocationIdToDelete = (id = '') => {
        setState(prevState => ({ ...prevState, locationIdToDelete: id }));
    };


    /**
     * Deletes the location with the staged location ID.
     * @returns {Promise<void>}
     */
    const deleteLocation = async () => {
        try {
            if (!locationIdToDelete) {
                return;
            }

            const location = document.querySelector(`[data-location-id="${locationIdToDelete}"]`);

            location.style.transform = 'translateX(2rem)';
            location.style.opacity = '0';

            await utils.sleep(450);

            const locationCopy = utils.copy(locations);
            const updatedLocations = locationCopy.filter(l => l._id !== locationIdToDelete);

            setState(prevState => ({ ...prevState, locations: updatedLocations }));

            await api.deleteLocationById(locationIdToDelete);

            openSnackbar('Location has been deleted successfully');

        } catch (error) {
            console.error(error.message);
            openSnackbar('Failed to delete location');
        }
    };


    /**
     * Fetches all locations and saves them to the local state.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchLocations = useCallback(async () => {
        try {
            const res = await api.getAllLocations();

            await utils.sleep(450);

            setState(prevState => ({ ...prevState, locations: res, isLoading: false }));

        } catch (error) {
            console.error(error.message);
        }
    }, []);


    /**
     * Fetches all locations when the fetchLocation
     * function changes.
     */
    useEffect(fetchLocations, [fetchLocations]);


    return (
        <>
            <div className={styling.header}>
                <h1>Locations</h1>
                <Button onClick={toggleAddNewLocationVisibility}>Create</Button>
            </div>

            <ul className={styling.list} hidden={!locations.length || isLoading}>
                {locations.map((location, i) => (
                    <li
                        className={styling.location}
                        data-location-id={location._id}
                        style={{ animationDelay: i * 0.15 + 's' }}
                        key={location._id}
                    >
                        <Link className={styling.wrapper} to={'/locations/' + location._id}>
                            <div className={styling.box}>
                                <div className={styling.title}>{location.name}</div>
                                <div className={styling.subtitle}>Name</div>
                            </div>

                            <div className={styling.box}>
                                <div className={styling.title}>{location.menuMetaData?.name || 'n/a'}</div>
                                <div className={styling.subtitle}>Menu</div>
                            </div>

                            <div className={styling.box}>
                                <div className={styling.title}>n/a</div>
                                <div className={styling.subtitle}>Open Orders</div>
                            </div>
                        </Link>

                        <div className={styling.actions}>
                            <button className={styling.action} onClick={() => stageLocationIdToDelete(location._id)}>
                                <IoTrashOutline/>
                            </button>
                        </div>
                    </li>
                ))}
            </ul>

            <Placeholder
                title="You don't have any locations yet"
                callToAction={toggleAddNewLocationVisibility}
                hidden={locations.length || isLoading}
            >
                Locations help you organizing orders if you offer your services in different areas.
            </Placeholder>

            <Spinner hidden={!isLoading}/>

            <Confirmation
                open={!!locationIdToDelete}
                title="Delete Location"
                message="Are you sure you want to delete this location and all its orders. This is permanent and cannot be undone."
                cancelHandler={stageLocationIdToDelete}
                confirmHandler={deleteLocation}
            />

            <NewLocation
                open={addNewLocationVisible}
                fetchLocations={fetchLocations}
                close={toggleAddNewLocationVisibility}
            />
        </>
    );
};

export default Locations;