import React, { useRef, useCallback } from 'react';
import { IoHome, IoLayers, IoLogOutOutline, IoLocationSharp, IoRadio, IoSettingsSharp } from 'react-icons/io5';
import { withRouter, Link } from 'react-router-dom';

import { useStore } from 'context/';
import { useOnClickOutside } from 'hooks/';

import logo from 'assets/logos/ido.svg';

import styling from './Nav.module.scss';

const Nav = ({ history }) => {
    // Store
    const [store, dispatch] = useStore();


    // Refs
    const menuRef = useRef({});


    /**
     * Changes the visibility of the settings menu
     * according to the provided boolean.
     * @type {(function(*=): void)|*}
     */
    const changeMenuVisibility = useCallback((open) => {
        dispatch({ type: 'update', payload: { settingsMenuOpen: open } });
    }, [dispatch]);


    /**
     * Signs out the current user and redirects them to the sign in.
     */
    const signOut = () => {
        localStorage.clear();
        history.push('/auth/sign-in');
        changeMenuVisibility(false);
    };


    // Close the settings menu if clicked outside
    useOnClickOutside(menuRef, () => changeMenuVisibility(false));


    return (
        <nav className={styling.nav}>
            <div className={styling.container}>
                <img src={logo} className={styling.logo} alt="logo"/>

                <div className={styling.wrapper}>
                    <div className={styling.userWrapper}>
                        <div className={styling.user}>
                            <div>{store?.user?.name || ''}</div>
                            <div className={styling.email}>{store?.user?.email || ''}</div>
                        </div>

                        <div className={styling.avatar}>{store?.user?.name?.[0] || ''}</div>
                    </div>

                    <div className={styling.separator}/>

                    <div
                        className={store.settingsMenuOpen ? styling.burgerActive : styling.burger}
                        data-tut="onboarding-step-1"
                        onClick={() => dispatch({ type: 'update', payload: { settingsMenuOpen: true } })}
                    >
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>

                <div
                    className={styling.menu}
                    data-tut="onboarding-step-2"
                    ref={menuRef}
                    hidden={!store.settingsMenuOpen}
                >
                    <ul>
                        <li className={styling.item}>
                            <Link to="/">
                                <div className={styling.icon}>
                                    <IoHome/>
                                </div>
                                Home
                            </Link>
                        </li>

                        <li className={styling.item}>
                            <Link to="/orders">
                                <div className={styling.icon}>
                                    <IoRadio/>
                                </div>
                                Live Orders
                            </Link>
                        </li>

                        <li className={styling.item}>
                            <Link to="/locations">
                                <div className={styling.icon}>
                                    <IoLocationSharp/>
                                </div>
                                All Locations
                            </Link>
                        </li>

                        <li className={styling.item}>
                            <Link to="/menus">
                                <div className={styling.icon}>
                                    <IoLayers/>
                                </div>
                                All Menus
                            </Link>
                        </li>

                        <li className={styling.item} hidden={store?.user?.role !== 'owner'}>
                            <Link to="/settings">
                                <div className={styling.icon}>
                                    <IoSettingsSharp/>
                                </div>
                                Settings
                            </Link>
                        </li>

                        <li className={styling.item} onClick={signOut}>
                            <div className={styling.icon}>
                                <IoLogOutOutline/>
                            </div>
                            <div>Sign Out</div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default withRouter(Nav);