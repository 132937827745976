import React, { useState } from 'react';
import { IoPencil, IoHelpCircle } from 'react-icons/io5';
import Tour from 'reactour';

import QRCode from 'components/UI/qrCode/QRCode';
import Edit from './edit/Edit';

import { useMenuContext } from '../context/';
import config from 'config/';
import tours from 'tours/';
import utils from 'utils/';

import styling from './Settings.module.scss';

const Settings = () => {
    // State
    const [{ editIsShown }, setState] = useState({
        editIsShown: false
    });


    // Hooks
    const [{ showTour, id, logo, color }, dispatch] = useMenuContext();


    /**
     * Sets the visibility of the edit modal.
     */
    const setEditModalVisibility = (isVisible) => {
        setState(prevState => ({ ...prevState, editIsShown: isVisible }));
    };


    /**
     * Toggles the tour visibility.
     */
    const toggleTourVisibility = () => {
        dispatch({ type: 'update', payload: { showTour: !showTour } });
    };


    return (
        <>
            <div className={styling.settings} data-tut="menu-step-1">
                <div className={styling.header}>
                    <h1>Settings</h1>

                    <button
                        className={styling.icon}
                        data-tut="menu-step-2"
                        onClick={() => setEditModalVisibility(true)}
                    >
                        <IoPencil/>
                        <span>Edit</span>
                    </button>
                </div>

                <button className={styling.help} onClick={toggleTourVisibility}>
                    <IoHelpCircle/>
                    <span>Help</span>
                </button>

                <div className={styling.container}>
                    <div className={styling.wrapper}>
                        <div className={styling.column}>
                            <h6>Logo</h6>

                            <div className={styling.logoWrapper}>
                                <img src={logo} className={styling.logo} alt="logo" hidden={!logo}/>

                                <div className={styling.overlay} hidden={logo}>
                                    Click on "Edit" to add your logo
                                </div>
                            </div>
                        </div>

                        <div className={styling.column}>
                            <h6>Main Color</h6>

                            <div className={styling.color} style={{ backgroundColor: color.hex }}/>
                        </div>
                    </div>

                    <div className={styling.qrCode}>
                        <h6>QR Code</h6>

                        <div className={styling.qrCodeWrapper} hidden={id}>
                            <div className={styling.overlay}>Available after saving</div>
                        </div>

                        <QRCode value={config.connectivity.menuUrl + 'preview/' + id} hidden={!id}/>
                    </div>
                </div>
            </div>


            <Edit
                open={editIsShown}
                closeHandler={() => setEditModalVisibility(false)}
            />


            <Tour
                isOpen={showTour}
                steps={tours.menu}
                rounded={10}
                onRequestClose={toggleTourVisibility}
                accentColor={utils.getCSSVariable('--color-1')}
                badgeContent={(c, t) => c + '/' + t}
                getCurrentStep={(step) => {
                    if (step === 2 && !editIsShown) {
                        setState(prevState => ({ ...prevState, editIsShown: true }));
                    }

                    if ((step < 2 || step > 4) && editIsShown) {
                        setState(prevState => ({ ...prevState, editIsShown: false }));
                    }
                }}
                update={editIsShown.toString()}
                updateDelay={450}
                startAt={0}
                onAfterOpen={() => {
                    document.body.style.overflow = 'hidden';
                }}
                onBeforeClose={() => {
                    document.body.style.overflow = 'auto';
                }}
            />
        </>
    );
};

export default Settings;